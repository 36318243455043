import { CurrencyFlag } from '@common/components/CurrencyToFlag'
import { FormattedNumber } from '@common/components/FormattedNumber'
import { SmartTable } from '@common/components/SmartTable'
import { useSelectedHoldingAccount } from '@common/hooks/useHoldings'
import { sum } from '@common/utils/sum'

import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { formatNumber } from '@carnegie/digital-channels-frontend'
import { Box, Breakpoint, FlexRow, Segment, Td, Text, Tr, useBreakpoint } from '@carnegie/duplo'

import { observer } from 'mobx-react-lite'

import { Currency, useCurrencyPositions } from '../useCurrencyPositions'
import { CurrencyPositionsMobile } from './CurrencyPositionMobile'
import { CurrencyPositionsFooter, CurrencyPositionsMobileFooter } from './CurrencyPositionsFooter'

const STORAGE_KEY = `CURRENCY_POSITION`

type CurrencyColumnId = 'name' | 'quantity' | 'marketValue' | 'fxRate'

export const CurrencyPositions = observer(() => {
  const { t } = useTranslation()
  const { account } = useSelectedHoldingAccount()
  const breakpoint = useBreakpoint()
  const isExtraSmallScreen = breakpoint <= Breakpoint.Xs
  const { currencies } = useCurrencyPositions(account?.holdings?.currencies ?? [])

  const tableData = useMemo(
    () => ({
      currencies: currencies?.filter((c) => c?.marketValue != null) ?? [],
      totalMarketValue: sum('marketValue', currencies?.filter((c) => c?.marketValue != null) ?? []),
    }),
    [currencies]
  )

  const renderRow = useCallback(
    ({ row: currency, index }) => {
      if (isExtraSmallScreen) {
        return <CurrencyPositionsMobile currency={currency} key={currency?.currencyName ?? index} />
      }
      return <CurrencyRow key={currency?.currencyName ?? index} currency={currency} />
    },
    [isExtraSmallScreen]
  )

  const renderFooterRow = useCallback(() => {
    if (isExtraSmallScreen) {
      return <CurrencyPositionsMobileFooter totalMarketValue={tableData.totalMarketValue} />
    }
    return <CurrencyPositionsFooter totalMarketValue={tableData.totalMarketValue} />
  }, [isExtraSmallScreen, tableData.totalMarketValue])

  const columns = useMemo(
    () => [
      {
        id: 'name' as const,
        width: 'minmax(0,2fr)',
        renderHeader: () => t('Valuta'),
        sortBy: ({ row }: { row: Currency }) => row.currencyName,
      },
      {
        id: 'quantity' as const,
        width: '1fr',
        align: 'right' as const,
        renderHeader: () => t('Saldo'),
        sortBy: ({ row }: { row: Currency }) => row.quantity,
      },
      {
        id: 'marketValue' as const,
        width: '1fr',
        align: 'right' as const,
        renderHeader: () => t('Saldo') + ` (SEK)`,
        sortBy: ({ row }: { row: Currency }) => row.marketValue,
      },
      {
        id: 'fxRate' as const,
        width: '1fr',
        align: 'right' as const,
        renderHeader: () => t('Valutakurs'),
        sortBy: ({ row }: { row: Currency }) => row.fxRate,
      },
    ],
    [t]
  )

  const tableContent = useMemo(() => {
    if (isExtraSmallScreen) {
      return (
        <Box>
          {tableData.currencies.map((currency, index) => (
            <CurrencyPositionsMobile currency={currency} key={index} />
          ))}
          <CurrencyPositionsMobileFooter totalMarketValue={tableData.totalMarketValue} />
        </Box>
      )
    }

    return (
      <SmartTable<Currency, CurrencyColumnId>
        renderMode={isExtraSmallScreen ? 'custom' : 'table'}
        sessionStorageKey={STORAGE_KEY}
        data={tableData.currencies}
        defaultSortBy="name"
        columns={columns}
        renderRow={renderRow}
        renderFooterRow={renderFooterRow}
      />
    )
  }, [isExtraSmallScreen, tableData.currencies, tableData.totalMarketValue, columns, renderRow, renderFooterRow])

  return (
    <Segment noContentPadding title={t('Likvida medel')} variant="contained" headingVariant="overline">
      {tableContent}
    </Segment>
  )
})

type CurrencyRowProps = {
  currency: Currency
}

const CurrencyRow = observer(({ currency }: CurrencyRowProps) => {
  const { currencyName, quantity, marketValue, fxRate } = currency

  //https://dev.azure.com/carnegieinvestmentbank/CarnegieIT/_workitems/edit/74700
  if (marketValue === null) return null

  return (
    <Tr>
      <Td>
        <FlexRow css={{ maxWidth: 150 }} alignItems="center">
          <Box flexShrink={0}>
            <CurrencyFlag currency={currencyName} size={16} />
          </Box>
          <Text variant="body2" truncate ml={4}>
            {currencyName}
          </Text>
        </FlexRow>
      </Td>
      <Td>
        <FormattedNumber justifyContent="flex-end" value={quantity} unit={currencyName} decimals={2} />
      </Td>
      <Td>
        <FormattedNumber justifyContent="flex-end" unit="SEK" value={marketValue} decimals={2} />
      </Td>
      <Td textAlign="right">
        <Text variant="body2">{formatNumber(fxRate, { decimals: 2 })}</Text>
      </Td>
    </Tr>
  )
})
