import { useEffect, useRef, useState } from 'react'

import { logInfrontError } from '../../../utils/infrontAppInsights'
import { useInfrontSDK } from './useInfrontSdk'

type TimeSeriesDataPoint = {
  last: number
  open: number
  low: number
  high: number
  date: Date
}

export function useInfrontTimeSeries(instrument: Infront.Instrument, numOfDays: number) {
  const feed = instrument?.feed
  const ticker = instrument?.ticker
  const unsubscribeRef = useRef<() => void>()
  const unbindRef = useRef<(() => void)[]>([])
  const { infrontSDK } = useInfrontSDK()
  const [dataPoints, setDataPoints] = useState<TimeSeriesDataPoint[]>([])

  useEffect(() => {
    if (!infrontSDK || !feed || !ticker) return

    unsubscribeRef.current = infrontSDK.get(
      InfrontSDK.timeSeries({
        id: { feed, ticker },
        daysBack: numOfDays,
        resolution: { unit: InfrontSDK.Unit.Day, value: 1 },
        adjustDividends: false,
        adjustSplits: false,
        fields: [],
        onData: (ts) => {
          const unbind = ts.observe({
            reInit: (newDataPoints: TimeSeriesDataPoint[]) => {
              if (newDataPoints && newDataPoints.length > 0) {
                setDataPoints(newDataPoints)
              }
            },
          })
          unbindRef.current.push(unbind)
        },
        onError: (_) => {
          logInfrontError(_)
          setDataPoints([])
        },
      })
    )

    return () => {
      setDataPoints([])
      unsubscribeRef.current && unsubscribeRef.current()
      // eslint-disable-next-line react-hooks/exhaustive-deps
      unbindRef.current.forEach((unbind) => unbind())
    }
  }, [infrontSDK, feed, ticker, numOfDays])

  return dataPoints
}
