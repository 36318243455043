import { InfrontInstrument } from '@common/api/response'

import { useEffect, useRef } from 'react'

import { appInsights } from '../../../../appInsights'
import { InfrontChartPeriod } from '../infrontChartPeriod'
import { useInfrontWidget } from './useInfrontWidget'

// We want to set this to true but currently infront has too many issues with it
// When set to false we will unmount / recreate the whole widget every time the period changes
const ENABLE_FAST_PERIOD_SWITCHING = false

export function useInfrontChart(
  infrontInstrument: InfrontInstrument,
  period: InfrontChartPeriod,
  customOptions: Infront.ChartWidgetOptions2
) {
  const chartStartPeriod = useRef<InfrontChartPeriod>(null)

  const { containerId, widget, widgetLoaded } = useInfrontWidget(
    (infrontUi, targetId) => {
      // We should run this even with a falsy infrontInstrument so we can show a blank chart
      let options = new Infront.ChartWidgetOptions2()
      options.defaultPeriod = period
      options.instruments = [new Infront.Instrument(infrontInstrument?.feed, infrontInstrument?.ticker)]
      options.showVolume = true
      options.streaming = true
      options.chartTypeID = Infront.ChartType.area
      options.invertYAxis = true
      options.chartUI = {
        tooltipVersion: 'advanced',
        periodMenu: false, // Good for debugging periods
        indicatorMenu: false,
        expandButton: false,
        chartTypeMenu: false,
        searchBox: false,
      }

      chartStartPeriod.current = period

      options.legend = 'FULL_NAME'

      options = { ...options, ...customOptions } as Infront.ChartWidgetOptions2

      try {
        return infrontUi.chartWidget2(targetId, options)
      } catch (err) {
        // When having an instrument without access rights etc the infront widget might blow up...
        appInsights.trackException({ exception: err })
        console.error('Error creating infront chart widget', err)
        return undefined
      }
    },
    [infrontInstrument?.feed, infrontInstrument?.ticker, ENABLE_FAST_PERIOD_SWITCHING ? undefined : period]
  )

  useEffect(() => {
    if (ENABLE_FAST_PERIOD_SWITCHING) {
      // We do not need to change the period if it is already the default one for the widget
      if (widget) {
        // *** NOTE ***
        // This is a bit of an unofficial / internal way of changing the selected period sent to us by Sebastian S. at Infront
        // Setting the second argument to true makes it impossible to set certain periods like 3M and 3Y, however setting user interaction to false
        // seems to be a way of making "any period go"

        widget.periodSelected(Infront.ChartPeriodButton.kPeriodIds[period], false)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [period])

  return { containerId, widget, widgetLoaded }
}
