import { BuySellMenuItem, PlaceFundOrderMenuItem, TransactionMenuItem } from '@common/components/ActionButton'
import { FormattedNumber, FormattedPercentage } from '@common/components/FormattedNumber'
import { MoreMenuWatchlist } from '@common/components/MoreMenu'
import { ExpandTableButton, ExpandableTr } from '@common/components/SmartTable'
import { fireTrackEvent } from '@common/utils/analyticsEvent'
import { hasValue } from '@common/utils/hasValue'

import { useCallback, useMemo, useState } from 'react'

import { formatNumber } from '@carnegie/digital-channels-frontend'
import { Box, Paragraph, Td, Text, Tooltip } from '@carnegie/duplo'

import { observer } from 'mobx-react-lite'

import { useOpenInstrumentDrawer } from '../../../../drawers/instrumentDrawer/useOpenInstrumentDrawer'
import { useAccountHoldingsSideDrawer } from '../../accountHoldings/useAccountHoldingsSideDrawer'
import { InstrumentName } from '../InstrumentName'
import { UnderlyingAssetsRows } from '../UnderlyingAssetsRows'
import { ObservableTableInstrument } from '../useTableInstruments'
import { TableOverviewColumnId } from './TableOverview'

type TableOverviewRowProps = {
  instrument: ObservableTableInstrument
  accountCurrencyCode: string
  showPercentage: boolean
  transactionOnClick?: () => void
  isFund: boolean
  showMoreColumns: boolean
  columns: { id?: TableOverviewColumnId }[]
}

/**
 * Renders a table row for an instrument with expandable details and various financial metrics
 */
export const TableOverviewRow = observer(function TableOverviewRow({
  instrument,
  accountCurrencyCode,
  showPercentage,
  transactionOnClick,
  isFund,
  showMoreColumns,
  columns,
}: TableOverviewRowProps) {
  const { openInstrumentDrawer } = useOpenInstrumentDrawer()
  const { openSideDrawer } = useAccountHoldingsSideDrawer()
  const [expanded, setExpanded] = useState(false)

  const {
    acquisitionCost,
    acquisitionCostOriginal,
    averageAcquisitionPrice,
    averageAcquisitionPriceOriginal,
    carnegieInstrumentGroup,
    currencyCode,
    instrumentPriceDateTime,
    isoCountryCode,
    lastPrice,
    marketIso,
    marketValue,
    marketValueOriginal,
    name,
    priceChangeToday,
    priceChangeTodayOriginal,
    priceChangeTodayRatio,
    priceChangeOneDayRatio,
    priceChangeOneDay,
    priceChangeOneDayOriginal,
    unrealized,
    unrealizedRatio,
    quantity,
  } = instrument

  const showFlag = carnegieInstrumentGroup !== 'MutualFunds'
  const showTooltipInInstrumentCurrency = currencyCode && accountCurrencyCode !== currencyCode
  const hasExpanderColumn = columns.some((columnDef) => columnDef.id === 'expander-button')

  // Memoize computed values
  const tooltipValues = useMemo(() => {
    const _priceChangeHasValue = hasValue(isFund ? priceChangeOneDayOriginal : priceChangeTodayOriginal)
    const _priceChangeValue = isFund
      ? `${formatNumber(priceChangeOneDayOriginal, { decimals: 0 })} ${currencyCode}`
      : `${formatNumber(priceChangeTodayOriginal, { decimals: 0 })} ${currencyCode}`

    return {
      priceChangeHasValue: _priceChangeHasValue,
      priceChangeOneDayChangeTodayTooltip: _priceChangeHasValue ? _priceChangeValue : undefined,
      marketValueOriginalTooltip:
        showTooltipInInstrumentCurrency && marketValueOriginal
          ? `${formatNumber(marketValueOriginal, { decimals: 0 })} ${currencyCode}`
          : undefined,
      averageAcquisitionPriceOriginalTooltip:
        showTooltipInInstrumentCurrency && averageAcquisitionPriceOriginal?.amount
          ? `${formatNumber(averageAcquisitionPriceOriginal?.amount, { decimals: 2 })} ${currencyCode}`
          : undefined,
      acquisitionCostOriginalTooltip:
        showTooltipInInstrumentCurrency && acquisitionCostOriginal?.amount
          ? `${formatNumber(acquisitionCostOriginal?.amount, { decimals: 0 })} ${currencyCode}`
          : undefined,
      timeOrDate:
        instrumentPriceDateTime && InfrontUtil.formatStandardTimestamp(new Date(instrumentPriceDateTime), true),
    }
  }, [
    isFund,
    priceChangeOneDayOriginal,
    priceChangeTodayOriginal,
    currencyCode,
    showTooltipInInstrumentCurrency,
    marketValueOriginal,
    averageAcquisitionPriceOriginal?.amount,
    acquisitionCostOriginal?.amount,
    instrumentPriceDateTime,
  ])

  const handleExpand = useCallback(() => {
    if (!expanded) {
      fireTrackEvent('Holdings', 'expand_underlying_fundholding')
    }
    setExpanded(!expanded)
  }, [expanded])

  const handleInfoButtonClick = useCallback(() => {
    fireTrackEvent('Holdings', 'click_showmore_underlying_fundholding')
    openSideDrawer()
  }, [openSideDrawer])

  const handleInstrumentClick = useCallback(() => {
    openInstrumentDrawer(instrument)
  }, [openInstrumentDrawer, instrument])

  return (
    <>
      <ExpandableTr expanded={expanded}>
        {hasExpanderColumn && (
          <Td data-testid="expander-column">
            {instrument.hasDetails && <ExpandTableButton expanded={expanded} onClick={handleExpand} />}
          </Td>
        )}
        <Td data-testid="instrument-name-column">
          <InstrumentName
            showFlag={showFlag}
            name={name}
            reservedQuantities={instrument.reservedQuantities}
            isoCountryCode={isoCountryCode ? isoCountryCode : marketIso}
            showInfoButton={instrument.hasDetails}
            onInfoButtonClick={handleInfoButtonClick}
            onClick={handleInstrumentClick}
          />
        </Td>
        <Td data-testid="quantity-column" align="right">
          <Paragraph variant="body2">{formatNumber(quantity, { decimals: { min: 0, max: 10 } })}</Paragraph>
        </Td>
        {showMoreColumns && (
          <Td data-testid="price-change-column">
            {tooltipValues.priceChangeHasValue && showTooltipInInstrumentCurrency ? (
              <Tooltip
                placement="bottom-end"
                title={
                  <Text variant="support2" color="off-white">
                    {tooltipValues.priceChangeOneDayChangeTodayTooltip}
                  </Text>
                }
              >
                <FormattedNumber
                  decimals={0}
                  justifyContent="flex-end"
                  value={isFund ? priceChangeOneDay : priceChangeToday}
                  unit={accountCurrencyCode}
                  colorized
                />
              </Tooltip>
            ) : (
              <FormattedNumber
                decimals={0}
                justifyContent="flex-end"
                value={isFund ? priceChangeOneDay : priceChangeToday}
                unit={accountCurrencyCode}
                colorized
              />
            )}
          </Td>
        )}
        <Td data-testid="price-change-ratio-column">
          {showPercentage ? (
            <FormattedPercentage
              value={isFund ? priceChangeOneDayRatio : priceChangeTodayRatio}
              ratio
              decimals={2}
              justifyContent={'flex-end'}
              colorized
            />
          ) : tooltipValues.priceChangeHasValue && showTooltipInInstrumentCurrency ? (
            <Tooltip
              placement="bottom-end"
              title={
                <Text variant="support2" color="off-white">
                  {tooltipValues.priceChangeOneDayChangeTodayTooltip}
                </Text>
              }
            >
              <FormattedNumber
                decimals={0}
                justifyContent="flex-end"
                value={isFund ? priceChangeOneDay : priceChangeToday}
                unit={accountCurrencyCode}
                colorized
              />
            </Tooltip>
          ) : (
            <FormattedNumber
              decimals={0}
              justifyContent="flex-end"
              value={isFund ? priceChangeOneDay : priceChangeToday}
              unit={accountCurrencyCode}
              colorized
            />
          )}
        </Td>
        <Td data-testid="last-price-column">
          <Tooltip placement="bottom-end" title={tooltipValues.timeOrDate}>
            <FormattedNumber justifyContent="flex-end" value={lastPrice} decimals={2} unit={currencyCode} />
          </Tooltip>
        </Td>
        <Td data-testid="market-value-column">
          {showTooltipInInstrumentCurrency && tooltipValues.marketValueOriginalTooltip ? (
            <Tooltip
              placement="bottom-end"
              title={
                <Text variant="support2" color="off-white">
                  {tooltipValues.marketValueOriginalTooltip}
                </Text>
              }
            >
              <FormattedNumber decimals={0} justifyContent="flex-end" unit="SEK" value={marketValue} />
            </Tooltip>
          ) : (
            <FormattedNumber decimals={0} justifyContent="flex-end" unit="SEK" value={marketValue} />
          )}
        </Td>
        {showMoreColumns && (
          <Td data-testid="unrealized-column-show-more">
            {<FormattedNumber decimals={0} justifyContent="flex-end" unit="SEK" value={unrealized} colorized />}
          </Td>
        )}
        <Td data-testid="unrealized-column">
          {showPercentage ? (
            <FormattedPercentage value={unrealizedRatio} ratio decimals={2} justifyContent={'flex-end'} colorized />
          ) : (
            <FormattedNumber decimals={0} justifyContent="flex-end" unit="SEK" value={unrealized} colorized />
          )}
        </Td>
        {showMoreColumns && (
          <Td data-testid="average-acquisition-price-column">
            {showTooltipInInstrumentCurrency && tooltipValues.averageAcquisitionPriceOriginalTooltip ? (
              <Tooltip
                placement="bottom-end"
                title={
                  <Text variant="support2" color="off-white">
                    {tooltipValues.averageAcquisitionPriceOriginalTooltip}
                  </Text>
                }
              >
                <FormattedNumber
                  justifyContent="flex-end"
                  value={averageAcquisitionPrice?.amount}
                  unit={averageAcquisitionPrice?.currencyCode}
                  decimals={2}
                />
              </Tooltip>
            ) : (
              <FormattedNumber
                justifyContent="flex-end"
                value={averageAcquisitionPrice?.amount}
                unit={averageAcquisitionPrice?.currencyCode}
                decimals={2}
              />
            )}
          </Td>
        )}
        {showMoreColumns && (
          <Td data-testid="acquisition-cost-column">
            {showTooltipInInstrumentCurrency && tooltipValues.acquisitionCostOriginalTooltip ? (
              <Tooltip
                placement="bottom-end"
                title={
                  <Text variant="support2" color="off-white">
                    {tooltipValues.acquisitionCostOriginalTooltip}
                  </Text>
                }
              >
                <FormattedNumber
                  decimals={0}
                  justifyContent="flex-end"
                  value={acquisitionCost?.amount}
                  unit={acquisitionCost?.currencyCode}
                />
              </Tooltip>
            ) : (
              <FormattedNumber
                decimals={0}
                justifyContent="flex-end"
                value={acquisitionCost?.amount}
                unit={acquisitionCost?.currencyCode}
              />
            )}
          </Td>
        )}
        <Td data-testid="more-menu-column">
          <Box>
            <MoreMenuWatchlist instrumentIdContainer={instrument}>
              {isFund ? (
                <>
                  <PlaceFundOrderMenuItem instrumentIdContainer={instrument} fundOrderType={'buy'} />
                  <PlaceFundOrderMenuItem instrumentIdContainer={instrument} fundOrderType={'sell'} />
                </>
              ) : (
                <BuySellMenuItem instrumentIdContainer={instrument} />
              )}
              <TransactionMenuItem onClick={transactionOnClick} />
            </MoreMenuWatchlist>
          </Box>
        </Td>
      </ExpandableTr>
      {expanded && <UnderlyingAssetsRows instrument={instrument} columns={columns} />}
    </>
  )
})

TableOverviewRow.displayName = 'TableOverviewRow'
