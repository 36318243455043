import { useInfrontSDK } from '@common/hooks/infront/sdk/useInfrontSdk'
import { ResizeObserver } from '@juggle/resize-observer'

import { createRoot } from 'react-dom/client'

import { pboSharedConfig } from '@carnegie/digital-channels-frontend'
import '@carnegie/duplo/lib/duplo.css'

import { configure } from 'mobx'

import { version } from '../package.json'
import App from './App'
import { initApplicationInsights } from './appInsights'
import './i18n'
import { useMessagesService } from './pages/messages/hooks/useMessagesService'

console.log(`Loading PBO version ${version}`)

configure({
  enforceActions: 'never',
})

// Configure pbo-shared compatibility layer
pboSharedConfig.useInfrontSDK = useInfrontSDK
pboSharedConfig.useMessagesService = useMessagesService

// If we get to this point we should have all we need to run the app, so remove the global error handler.
// It could be triggered accidentally if we don't catch all errors in an error boundary, so better to be safe.
window.onerror = null

// Polyfill ResizeObserver for older Safari browsers.
window.ResizeObserver = window.ResizeObserver || ResizeObserver

async function prepare() {
  if (process.env.NODE_ENV === 'development' && import.meta.env.VITE_MOCK_API === 'true') {
    const { worker } = await import('./mocks/browser')
    return worker.start({
      onUnhandledRequest: 'bypass',
    })
  }
  return Promise.resolve()
}

if (!import.meta.env.VITE_DISABLE_SENTRY) {
  initApplicationInsights()
}

prepare().then(() => {
  const root = createRoot(document.getElementById('app'))
  root.render(<App />)
})
