import { AccountsResponse } from '@common/api/response'
import { compareBooleans } from '@common/utils/compareBoolean'

import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Badge,
  Box,
  FlexRow,
  ListItem,
  ListItemRow,
  Option,
  Select,
  SkeletonRect,
  Text,
  Tooltip,
} from '@carnegie/duplo'

import { ObservableAccount } from '../../pages/overview/accounts/useAccounts'
import { AccountWithUserSettings, useHoldingAccounts } from '../hooks/useHoldings'
import { getOwnerDescription } from '../utils/accountOwnerDescription'
import { FavoriteStar } from './FavoriteStar'

interface AccountSelectProps {
  selectedAccount: AccountsResponse | ObservableAccount
  onSelectAccount: (accountId: string) => void
  splitByTradable?: boolean
  disabled?: boolean
  error?: boolean
  helperText?: string
  id?: string
}

interface ListItemProps {
  account: Pick<AccountWithUserSettings, 'accountType' | 'number' | 'owners' | 'policyHolder' | 'userSettings'>
  isSelected: boolean
  divider: boolean
  splitAccounts?: boolean
  extraRow?: ReactNode
}

export const AccountSelectListItem = ({ account, divider, extraRow = null }: ListItemProps) => {
  const { t } = useTranslation()

  return (
    <ListItem
      width="full"
      divider={divider}
      support={
        account?.accountType?.description ? (
          <Tooltip title={t(account?.accountType?.description)}>
            <Badge size="large">{account?.accountType?.shortName}</Badge>
          </Tooltip>
        ) : (
          <Badge size="large">{account?.accountType?.shortName}</Badge>
        )
      }
    >
      <ListItemRow
        title={
          <FlexRow alignItems="center" gap={4}>
            <Text variant="subtitle1">{account.number}</Text>
            {account.userSettings?.isFavorite && (
              <FavoriteStar css={{ transform: 'translateY(-1px)' }} filled size="small" />
            )}
          </FlexRow>
        }
      />
      <ListItemRow
        title={
          <Text variant="support1" truncate color="text-low-emphasis">
            {getOwnerDescription(account.owners, account.policyHolder, t)}
          </Text>
        }
      />
      {extraRow && <ListItemRow title={extraRow} />}
    </ListItem>
  )
}

export const AccountSelect = function AccountSelect({
  selectedAccount,
  onSelectAccount,
  splitByTradable,
  disabled,
  error,
  helperText,
  id,
}: AccountSelectProps) {
  const { t } = useTranslation()
  const { accounts } = useHoldingAccounts()

  if (!accounts) return <SkeletonRect width="100%" height={40} />

  const tradableAccounts = accounts
    .filter((account) => account.isTradeable && account.isAccountStatusOpen)
    .sort((a, b) => compareBooleans(a.userSettings?.isFavorite ?? false, b.userSettings?.isFavorite ?? false))
  const tradableMenuItems = tradableAccounts.map((account, index) => {
    return (
      <Option key={account.id} value={account.id} p={0}>
        <AccountSelectListItem
          account={account}
          isSelected={selectedAccount?.id === account.id}
          divider={index < tradableAccounts.length - 1}
        />
      </Option>
    )
  })

  const nonTradableAccounts = accounts.filter((account) => !account.isTradeable || !account.isAccountStatusOpen)
  const nonTradableMenuItems = nonTradableAccounts.map((account, index) => {
    return (
      <Option disabled key={account.id} value={undefined} p={0}>
        <AccountSelectListItem
          account={account}
          isSelected={selectedAccount?.id === account.id}
          divider={index < nonTradableAccounts.length - 1}
        />
      </Option>
    )
  })

  let selectChildren: ReactNode = undefined
  if (splitByTradable) {
    selectChildren = [
      <Box pl={16} key="tillgängliga">
        <Text variant="overline" truncate color="text-low-emphasis">
          {t('Tillgängliga').toUpperCase()}
        </Text>
      </Box>,
      ...tradableMenuItems,
    ]

    if (nonTradableMenuItems.length > 0) {
      selectChildren = [
        ...selectChildren,
        <Box pt={16} pl={16} key="ej_tillgängliga">
          <Text variant="overline" truncate color="text-low-emphasis">
            {t('Ej tillgängliga').toUpperCase()}
          </Text>
        </Box>,
        ...nonTradableMenuItems,
      ]
    }
  } else {
    selectChildren = accounts
      .sort((a, b) => compareBooleans(a.userSettings?.isFavorite ?? false, b.userSettings?.isFavorite ?? false))
      .map((account, index) => {
        return (
          <Option key={account.id} value={account.id} p={0}>
            <AccountSelectListItem
              account={account}
              isSelected={selectedAccount?.id === account.id}
              divider={index < accounts.length - 1}
            />
          </Option>
        )
      })
  }

  return (
    <Select
      width="full"
      label={t('Välj konto')}
      disabled={disabled}
      error={error}
      helperText={helperText}
      value={selectedAccount?.id ?? ''}
      renderValue={(selectedValue) => {
        return selectedValue ? (
          <Text variant="input2" color={disabled ? 'text-disabled' : 'text-default'}>
            {selectedAccount?.number +
              ' (' +
              getOwnerDescription(selectedAccount?.owners, selectedAccount?.policyHolder, t) +
              ')'}
          </Text>
        ) : undefined
      }}
      onChange={(_, accountId) => {
        onSelectAccount(accountId)
      }}
      size="medium"
      id={id}
    >
      {selectChildren}
    </Select>
  )
}

AccountSelect.displayName = 'AccountSelect'
