import React, { useEffect, useState } from 'react'

import { useFormatDate } from '@carnegie/digital-channels-frontend'
import { useDuploTheme } from '@carnegie/duplo'

import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts/highstock'

const defaultOptions: Partial<Highcharts.Options> = {
  chart: {
    marginTop: 0,
    marginRight: 0,
    marginBottom: 0,
    marginLeft: 0,
    animation: false,
    backgroundColor: null,
  },
  rangeSelector: {
    enabled: false,
  },
  scrollbar: { enabled: false },
  navigator: { enabled: false },
  tooltip: {
    enabled: false,
  },
  credits: { enabled: false },
  xAxis: {
    tickPosition: 'inside',
    labels: {
      style: {
        color: 'rgb(136, 138, 142)',
        fontSize: '10px',
        letterSpacing: '0.4px',
      },
    },
  },
  yAxis: {
    tickAmount: 7,
    labels: {
      style: {
        color: 'rgb(136, 138, 142)',
        fontSize: '10px',
        letterSpacing: '0.4px',
      },
    },
  },
  plotOptions: {
    area: {
      enableMouseTracking: false,
    },
  },
}

type AreaChartProps = {
  dataName?: string
  dataPoints: { 0: number; 1: number }[]
  compareDataName?: string
  compareDataPoints?: { 0: number; 1: number }[]
  language: 'sv' | 'en'
  showAxis: boolean
  style?: React.CSSProperties
  options?: Partial<Highcharts.Options>
}

export const AreaChart = ({
  dataName = '',
  dataPoints = [],
  compareDataName = '',
  compareDataPoints = [],
  language = 'sv',
  showAxis = true,
  style,
  options = {},
}: AreaChartProps) => {
  const theme = useDuploTheme()
  const [chartOptions, setChartOptions] = useState(mergeDeep(defaultOptions, options))
  const { formatDate } = useFormatDate()

  const oneYear = 31_536_000_000

  useEffect(() => {
    Highcharts.setOptions({ lang: { decimalPoint: ',' } })

    const xPoints = dataPoints.map((d) => d[0])
    const xMin = isEmpty(dataPoints) ? 0 : Math.min(...xPoints)
    const xMax = isEmpty(dataPoints) ? 0 : Math.max(...xPoints)

    const allDataPoints = [...dataPoints, ...compareDataPoints]
    const yPoints = allDataPoints.map((d) => d[1])
    const yMin = isEmpty(allDataPoints) ? 0 : Math.min(...yPoints)

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const series: any = [
      {
        id: 'data-points',
        type: 'area',
        name: dataName,
        data: dataPoints,
        color: 'rgb(50, 107, 147)',
        fillColor: {
          linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
          stops: [
            [0, 'rgba(124,181,236, 1)'],
            [1, 'rgba(124,181,236, 0)'],
          ],
        },
        negativeColor: '#D76363',
        negativeFillColor: {
          linearGradient: { x1: 0, y1: 1, x2: 0, y2: 0 },
          stops: [
            [0, 'rgba(215, 99, 99, 1)'],
            [1, 'rgba(215, 99, 99, 0)'],
          ],
        },
        threshold: 0,
        tooltip: {
          valueDecimals: 2,
          valueSuffix: ' %',
          xDateFormat: '%Y-%m-%d',
        },
      },
    ]

    if (compareDataPoints.length > 0) {
      series.push({
        id: 'compare-data-points',
        type: 'area',
        name: compareDataName,
        data: compareDataPoints,
        color: theme.colors['golden-dream'],
        fillColor: {
          linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
          stops: [
            [0, 'rgba(124,181,236, 1)'],
            [1, 'rgba(124,181,236, 0)'],
          ],
        },
        negativeColor: '#D76363',
        negativeFillColor: {
          linearGradient: { x1: 0, y1: 1, x2: 0, y2: 0 },
          stops: [
            [0, 'rgba(215, 99, 99, 1)'],
            [1, 'rgba(215, 99, 99, 0)'],
          ],
        },
        threshold: 0,
        tooltip: {
          valueDecimals: 2,
          valueSuffix: ' %',
        },
      })
    }

    setChartOptions((_options) =>
      mergeDeep<Partial<Highcharts.Options>>(_options, {
        series,
        xAxis: {
          visible: showAxis,
          min: xMin,
          max: xMax,
          labels: {
            enabled: showAxis,
            formatter: function () {
              // If the chart is showing a longer period than one year, show the year
              if (xMax - xMin > oneYear) {
                return formatDate(this.value, 'MMM yy')
              }
              return formatDate(this.value, 'd MMM')
            },
          },
        },
        yAxis: {
          visible: showAxis,
          min: yMin,
          labels: {
            enabled: showAxis,
            formatter: function () {
              return `${this.value} %`
            },
          },
        },
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataName, dataPoints.length, compareDataName, compareDataPoints.length, showAxis, theme.colors, language])

  if (dataPoints.length === 0) return null

  return (
    <HighchartsReact
      containerProps={{ style }}
      constructorType="stockChart"
      highcharts={Highcharts}
      options={chartOptions}
    />
  )
}

function isEmpty(dataPoints: unknown[]) {
  if (!dataPoints) return false
  return dataPoints.length === 0
}

function isObject(item) {
  return item && typeof item === 'object' && !Array.isArray(item)
}

function mergeDeep<T extends object>(target: T, source: T) {
  const output = Object.assign({}, target)
  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach((key) => {
      if (isObject(source[key])) {
        if (!(key in target)) Object.assign(output, { [key]: source[key] })
        else output[key] = mergeDeep(target[key], source[key])
      } else {
        Object.assign(output, { [key]: source[key] })
      }
    })
  }
  return output
}
