import { InfrontInstrument } from '@common/api/response'

import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { useInfrontWidget } from './useInfrontWidget'

export function useInfrontOrderEntry({
  instrument,
  customOptions,
  accountId,
  portfolioChangedCallback,
  renderForApp,
}: {
  instrument: InfrontInstrument
  // When this changes the old widget will get destroyed and a new one created
  customOptions?: Partial<Infront.OrderEntryWidgetOptions>
  accountId: string
  portfolioChangedCallback?: (portfolio: Infront.Portfolio) => void
  renderForApp?: boolean
}) {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const [widgetState, setWidgetState] = useState<Infront.WidgetState>(Infront.WidgetState.Constructing)

  // Forces the widget to unmount and creates a new one, this is needed as a workaround for some unresolved
  // infront bugs related to initial price not getting auto filled after placing an order twice
  const [resetCount, setResetCount] = useState(0)

  const tradingPower = Infront.PortfolioFields.TRADING_POWER()
  const currentPosition = Infront.PositionFields.VOLUME()
  const grossPrice = Infront.OrderFields.S_ORDER_VALUE()
  tradingPower.dataType = Infront.DataType.Integer
  currentPosition.dataType = Infront.DataType.Integer

  const { containerId, widget } = useInfrontWidget(
    (infrontUi, targetId) => {
      let opts = new Infront.OrderEntryWidgetOptions()
      opts.advancedPaneMode = Infront.AdvancedPaneMode.hidden
      opts.autoFillPrice = true
      //FillIndicativePrice works only when autoFillPrice is set to true.
      //Using this option will fill the price field with indicative last closing price if the last price is null
      //(for example before market opens).
      opts.fillIndicativePrice = true
      opts.enableTickButtons = false
      opts.flexMode = true
      opts.instrument = new Infront.Instrument(instrument.feed, instrument.ticker)
      opts.persistValidityDate = true
      opts.popupContainer = Infront.WidgetPopupParent.FIRST_PARENT
      opts.rememberVolume = true
      opts.resetPopupPosition = true
      opts.setMaxDate = true
      opts.valueColumns = [tradingPower, currentPosition, grossPrice]
      opts.verboseConfirmation = true
      opts.widgetTitle = ''

      opts.widgetStateCallback = (state) => {
        setWidgetState(state)
      }

      // We need this so that single portfolio accounts still fire the portfolioChangedCallback function.
      opts.showDropdownForSinglePortfolio = true

      opts.portfolioChangedCallback = portfolioChangedCallback

      opts.persistState = false

      opts.afterConfirmButtons = [
        {
          label: t('Mina ordrar'),
          onClick: () => {
            renderForApp
              ? window.ReactNativeWebView?.postMessage('NAVIGATE_TO_ORDERLIST')
              : navigate(`/overview/orders/${accountId}`)
          },
        },
      ]

      opts.limitOrderFieldsSelectionInMain = [Infront.OrderEntryLimitField.validityDate]

      opts.overrideAndHideLimitOrderFields = [
        {
          field: Infront.OrderEntryLimitField.validityType,
          value: Infront.OrderEntryValidityType.selectDateOrGtd,
        },
        Infront.OrderEntryLimitField.activeCheckbox,
      ]

      const orderStatusChangeCallback = (state: Infront.OrderEntryState) => {
        if (customOptions.orderStatusChangeCallback) customOptions.orderStatusChangeCallback(state)
      }
      opts = { ...opts, ...customOptions, orderStatusChangeCallback } as Infront.OrderEntryWidgetOptions

      return infrontUi.orderEntryWidget(targetId, opts)
    },
    // When this changes the old widget will get destroyed and a new one created
    [resetCount],
    renderForApp
  )

  return {
    containerId,
    widget,
    widgetState,
    restartWidget: () => {
      setResetCount((count) => count + 1)
    },
  }
}
