import { InstrumentPosition } from '@common/api/response'
import { SkeletonLoader } from '@common/components/SkeletonLoader'
import { SmartTable } from '@common/components/SmartTable'
import { useSelectedHoldingAccount } from '@common/hooks/useHoldings'
import { sum } from '@common/utils/sum'

import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Box, Breakpoint, useBreakpoint } from '@carnegie/duplo'

import { observer } from 'mobx-react-lite'

import { ObservableTableInstrument, useTableInstruments } from '../useTableInstruments'
import { TableAboutFooter, TableAboutMobileFooter } from './TableAboutFooter'
import { TableAboutMobile } from './TableAboutMobile'
import { TableAboutRow } from './TableAboutRow'

type TableAboutProps = {
  isFund: boolean
  holdingsInstruments: InstrumentPosition[]
  showPercentage: boolean
  tableSortStorageKey: string
}

export type TableAboutColumnId = 'expander-button' | 'name' | 'typeName' | 'allocation' | 'region' | 'weight' | 'button'

export const TableAbout = observer(function TableAbout({
  isFund,
  holdingsInstruments,
  showPercentage,
  tableSortStorageKey,
}: TableAboutProps) {
  const { t } = useTranslation()
  const breakpoint = useBreakpoint()
  const isExtraSmallScreen = breakpoint <= Breakpoint.Xs
  const { instruments } = useTableInstruments(holdingsInstruments)
  const { account: selectedAccount } = useSelectedHoldingAccount()
  const navigate = useNavigate()

  // Memoize calculations
  const tableData = useMemo(() => {
    const safeInstruments = instruments?.filter(Boolean) ?? []
    return {
      instruments: safeInstruments,
      totalWeight: sum(
        'weight',
        safeInstruments.filter((instrument) => instrument.weight != null)
      ),
      showExpanderColumn: safeInstruments.some((instrument) => instrument?.hasDetails),
    }
  }, [instruments])

  // Memoize callbacks
  const navigateToTransactions = useCallback(
    (instrument: ObservableTableInstrument) => {
      if (!selectedAccount?.id) {
        throw new Error('Cannot navigate to transactions: Account ID is missing')
      }
      if (!instrument?.key) {
        throw new Error('Cannot navigate to transactions: Instrument key is missing')
      }

      navigate(
        `/overview/transactions/?accountid=${selectedAccount.id}&instruments=${JSON.stringify([
          instrument.key,
        ])}&from=2005-01-01`
      )
    },
    [navigate, selectedAccount?.id]
  )

  const renderRow = useCallback(
    (
      props: Parameters<
        NonNullable<Parameters<typeof SmartTable<ObservableTableInstrument, TableAboutColumnId>>[0]['renderRow']>
      >[0]
    ) => {
      const { row: instrument, columns: tableColumns } = props
      if (!instrument) return null

      if (isExtraSmallScreen) {
        return (
          <TableAboutMobile
            isFund={isFund}
            key={instrument.instrumentIdCarnegie}
            instrument={instrument}
            showPercentage={showPercentage}
            transactionOnClick={() => navigateToTransactions(instrument)}
          />
        )
      }
      return (
        <TableAboutRow
          isFund={isFund}
          columns={tableColumns}
          key={instrument.instrumentIdCarnegie}
          instrument={instrument}
          transactionOnClick={() => navigateToTransactions(instrument)}
        />
      )
    },
    [isFund, isExtraSmallScreen, navigateToTransactions, showPercentage]
  )

  const renderFooterRow = useCallback(
    (
      props: Parameters<
        NonNullable<Parameters<typeof SmartTable<ObservableTableInstrument, TableAboutColumnId>>[0]['renderFooterRow']>
      >[0]
    ) => {
      const { columns } = props
      if (isExtraSmallScreen) {
        return <TableAboutMobileFooter totalWeight={tableData.totalWeight} />
      }
      return <TableAboutFooter columns={columns} totalWeight={tableData.totalWeight} />
    },
    [isExtraSmallScreen, tableData.totalWeight]
  )

  // Memoize columns configuration
  const columnData = useMemo(
    () => [
      {
        id: 'expander-button' as const,
        visible: tableData.showExpanderColumn,
        width: 'auto',
      },
      {
        id: 'name' as const,
        width: 'minmax(0,2fr)',
        renderHeader: () => t('Namn'),
        sortBy: ({ row }: { row: ObservableTableInstrument }) => row.name,
      },
      {
        align: 'right' as const,
        id: 'typeName' as const,
        width: '1fr',
        renderHeader: () => t('Värdepapperstyp'),
        sortBy: ({ row }: { row: ObservableTableInstrument }) => row.typeName,
      },
      {
        align: 'right' as const,
        id: 'allocation' as const,
        width: '1fr',
        renderHeader: () => t('Tillgångsslag'),
        sortBy: ({ row }: { row: ObservableTableInstrument }) => row.allocation,
      },
      {
        align: 'right' as const,
        id: 'region' as const,
        width: '1fr',
        renderHeader: () => t('Region'),
        sortBy: ({ row }: { row: ObservableTableInstrument }) => row.region,
      },
      {
        align: 'right' as const,
        id: 'weight' as const,
        width: '1fr',
        renderHeader: () => t('Andel'),
        sortBy: ({ row }: { row: ObservableTableInstrument }) => row.weight,
      },
      {
        id: 'button' as const,
        width: 48,
      },
    ],
    [t, tableData.showExpanderColumn]
  )

  return (
    <Box>
      <SkeletonLoader
        p={16}
        height={300}
        loading={!tableData.instruments}
        noDataLoaded={tableData.instruments?.length === 0}
      >
        {() => (
          <SmartTable<ObservableTableInstrument, TableAboutColumnId>
            sessionStorageKey={tableSortStorageKey}
            data={tableData.instruments}
            defaultSortBy="name"
            columns={columnData}
            renderMode={isExtraSmallScreen ? 'custom' : 'table'}
            renderRow={renderRow}
            renderFooterRow={renderFooterRow}
          />
        )}
      </SkeletonLoader>
    </Box>
  )
})

TableAbout.displayName = 'TableAbout'
