import { InstrumentPosition } from '@common/api/response'
import { useSessionStorage } from '@common/stores/store'

import { useTranslation } from 'react-i18next'

import {
  Box,
  Paragraph,
  SegmentWithTabs,
  SideDrawer,
  Spacer,
  Tab,
  TabPanel,
  Tabs,
  TabsList,
  Text,
} from '@carnegie/duplo'

import { observer } from 'mobx-react-lite'

import { TableAbout } from '../holdingsTable/TableAbout/TableAbout'
import { TableAcquisition } from '../holdingsTable/TableAcquisition/TableAcquisition'
import { TableOverview } from '../holdingsTable/TableOverview/TableOverview'
import { TableOverviewPortfolio } from '../holdingsTable/TableOverview/TableOverviewPortfolio'
import { useAccountHoldingsSideDrawer } from './useAccountHoldingsSideDrawer'

type Props = {
  title: string
  instruments: InstrumentPosition[]
  showPercentage: boolean
  isFund?: boolean
  showMoreColumns: boolean
  tableSortStorageKey: string
  isPortfolioManagement?: boolean
  weightSummary?: {
    targetWeight: number
    actualWeight: number
  }
}

export const HoldingsSegment = observer(function HoldingsSegment({
  title,
  instruments,
  showPercentage,
  isFund,
  showMoreColumns,
  tableSortStorageKey,
  isPortfolioManagement,
  weightSummary,
}: Props) {
  const { t } = useTranslation()
  const sessionStorage = useSessionStorage()

  const STORAGE_KEY = `HOLDING_SEGMENT_${title?.toUpperCase()}`
  const initialTab = sessionStorage.getItem<string>(STORAGE_KEY, 'user') ?? 'tab-now'

  if (instruments?.length === 0) return null

  const { closeSideDrawer, isOpen } = useAccountHoldingsSideDrawer()

  return (
    <>
      <SideDrawer
        onClose={closeSideDrawer}
        open={isOpen}
        title={t('Fondens innehav')}
        // Pre-line makes sure newlines gets picked up
        sidebarContent={
          <Text variant="body2" css={{ whiteSpace: 'pre-line' }}>
            {t('UNDERLYING_ASSETS_SIDE_DRAWER_CONTENT')}
          </Text>
        }
      />
      <SegmentWithTabs
        data-testid={`holdings-segment-${title?.toLowerCase()}`}
        title={title}
        action={
          weightSummary && (
            <Box display="flex" gap={16}>
              <Text variant="overline">
                {t('VALD VIKT')}: {(weightSummary.targetWeight * 100).toFixed(0)}%
              </Text>
              <Text variant="overline">|</Text>
              <Text variant="overline">
                {' '}
                {t('AKTUELL VIKT')}: {(weightSummary.actualWeight * 100).toFixed(2)}%
              </Text>
            </Box>
          )
        }
        variant="contained"
        headingVariant="overline"
      >
        <Tabs defaultValue={initialTab} onChange={(tab) => sessionStorage.setItem(STORAGE_KEY, tab, 'user')}>
          <TabsList>
            <Tab value="tab-now">
              <Paragraph variant="body1">{t('Översikt')}</Paragraph>
            </Tab>
            <Tab value="tab-acquisition">
              <Paragraph variant="body1">{t('Anskaffning')}</Paragraph>
            </Tab>
            <Tab value="tab-about">
              <Paragraph variant="body1">{t('Allokering')}</Paragraph>
            </Tab>
          </TabsList>
          <TabPanel value="tab-now">
            <Box>
              {isPortfolioManagement ? (
                <TableOverviewPortfolio
                  holdingsInstruments={instruments}
                  showPercentage={showPercentage}
                  isFund={isFund}
                  showMoreColumns={showMoreColumns}
                  tableSortStorageKey={tableSortStorageKey + '_OVERVIEW'}
                />
              ) : (
                <TableOverview
                  holdingsInstruments={instruments}
                  showPercentage={showPercentage}
                  isFund={isFund}
                  showMoreColumns={showMoreColumns}
                  tableSortStorageKey={tableSortStorageKey + '_OVERVIEW'}
                />
              )}
            </Box>
          </TabPanel>
          <TabPanel value="tab-acquisition">
            <Box>
              <TableAcquisition
                isFund={isFund}
                holdingsInstruments={instruments}
                showPercentage={showPercentage}
                tableSortStorageKey={tableSortStorageKey + '_ACQUISITION'}
              />
            </Box>
          </TabPanel>
          <TabPanel value="tab-about">
            <Box>
              <TableAbout
                isFund={isFund}
                holdingsInstruments={instruments}
                showPercentage={showPercentage}
                tableSortStorageKey={tableSortStorageKey + '_ABOUT'}
              />
            </Box>
          </TabPanel>
        </Tabs>
      </SegmentWithTabs>
      <Spacer height={16} />
    </>
  )
})

HoldingsSegment.displayName = 'HoldingsSegment'
