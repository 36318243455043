import { InfrontInstrument } from '@common/api/response'

import { useInfrontWidget } from './useInfrontWidget'

type Props = {
  instrument: InfrontInstrument
  customOptions?: object
}

export function useInfrontIntradayTrades({ instrument, customOptions }: Props) {
  const { containerId } = useInfrontWidget(
    (infrontUi, targetId) => {
      let opts = new Infront.IntradayTradesWidgetOptions()
      opts.instrument = new Infront.Instrument(instrument.feed, instrument.ticker)
      opts.pageItems = 5
      opts.paging = false

      opts = { ...opts, ...customOptions }
      return infrontUi.intradayTradesWidget(targetId, opts)
    },
    [JSON.stringify(customOptions)]
  )

  return containerId
}
