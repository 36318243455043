import { AccountSelect } from '@common/components/AccountSelect'
import CreateReportSideDrawer from '@common/components/CreateReportSideDrawer'
import { SkeletonLoader } from '@common/components/SkeletonLoader'
import { SystemMessageList } from '@common/components/systemMessages'
import { useHoldingAccounts } from '@common/hooks/useHoldings'
import { useProfile } from '@common/hooks/useProfile'

import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import {
  Box,
  Breakpoint,
  Button,
  FlexRow,
  IconPlus,
  Paragraph,
  SegmentWithTabs,
  SideDrawer,
  Spacer,
  Tab,
  TabPanel,
  Tabs,
  TabsList,
  Text,
  useBreakpoint,
  useResponsiveProps,
} from '@carnegie/duplo'

import { observer } from 'mobx-react-lite'
import { useSWRConfig } from 'swr'

import { CustomerReports } from '../CustomerReports'
import { ReportsStatement } from '../ReportsStatement'

export const Reports = observer(function Reports() {
  const { t } = useTranslation()
  const { mutate } = useSWRConfig()
  const breakpoint = useBreakpoint()
  const rp = useResponsiveProps()
  const { accounts, isLoading } = useHoldingAccounts()
  const navigate = useNavigate()

  const [open, setOpen] = useState(false)

  const isExtraSmallScreen = breakpoint <= Breakpoint.Xs

  const { tab, subtab, id } = useParams<{ tab: string; id: string; subtab: string }>()

  const selectedAccount = accounts?.length === 1 ? accounts[0] : accounts?.find((account) => account?.id === id)

  const { error } = useProfile()
  return (
    <Box mt={rp([16, 16, 32])} spaceY={16}>
      <SystemMessageList segmentId="/profile/reports" />

      <FlexRow>
        <Box css={{ width: `${isExtraSmallScreen ? '50%' : '160px'}` }}>
          <AccountSelect
            id="reports-account-select"
            onSelectAccount={(accountId) => {
              const account = accounts.find((acc) => acc?.id === accountId)
              navigate(`/profile/${tab}/${subtab || 'archive'}/${account?.id}`, { replace: true })
            }}
            selectedAccount={selectedAccount}
          />
        </Box>
        <Box p={8} />
        <Button
          css={{ width: `${isExtraSmallScreen ? '50%' : '165px'}` }}
          startIcon={IconPlus}
          onClick={() => setOpen(!open)}
          variant="secondary"
          size="medium"
        >
          {t('Skapa rapport')}
        </Button>
        <SideDrawer
          title={t('Skapa rapport')}
          variant="slide"
          sidebarContent={
            <CreateReportSideDrawer
              initialAccount={selectedAccount}
              onAccountSelect={(account) =>
                navigate(`/profile/${tab}/${subtab || 'archive'}/${account?.id}`, { replace: true })
              }
              closeDrawer={() => {
                mutate(selectedAccount ? `customer-reports/${selectedAccount.id}` : undefined)
                setOpen(false)
              }}
            />
          }
          open={open}
          onClose={() => setOpen(false)}
        />
      </FlexRow>
      <Spacer height={32} />
      <SegmentWithTabs title={t('Rapporter')} variant="contained" headingVariant="overline">
        <Tabs
          value={subtab}
          defaultValue="archive"
          onChange={(newValue) => {
            navigate(`/profile/reports/${newValue}/${selectedAccount?.id ?? ''}`)
          }}
        >
          <TabsList>
            <Tab value="archive">
              <Paragraph variant="body1">{t('Rapportarkiv')}</Paragraph>
            </Tab>
            <Tab value="statement">
              <Paragraph variant="body1">{t('Kontobesked')}</Paragraph>
            </Tab>
          </TabsList>
          <TabPanel value="archive">
            <SkeletonLoader error={error} p={16} loading={isLoading}>
              {() =>
                selectedAccount?.id ? (
                  <CustomerReports selectedAccount={selectedAccount} />
                ) : (
                  <Box p={16}>
                    <Text variant="body2">{t('Välj ett konto för att se rapportarkiv.')}</Text>
                  </Box>
                )
              }
            </SkeletonLoader>
          </TabPanel>
          <TabPanel value="statement">
            <SkeletonLoader error={error} p={16} loading={isLoading}>
              {() =>
                selectedAccount?.id ? (
                  <ReportsStatement selectedAccount={selectedAccount} />
                ) : (
                  <Box p={16}>
                    <Text variant="body2">{t('Välj ett konto för att se kontoutdrag.')}</Text>
                  </Box>
                )
              }
            </SkeletonLoader>
          </TabPanel>
        </Tabs>
      </SegmentWithTabs>
      <Spacer height={16} />
    </Box>
  )
})

Reports.displayName = 'Reports'
