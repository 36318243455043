import { InstrumentPosition } from '@common/api/response'
import { SkeletonLoader } from '@common/components/SkeletonLoader'
import { SmartTable } from '@common/components/SmartTable'
import { useSelectedHoldingAccount } from '@common/hooks/useHoldings'
import { sum } from '@common/utils/sum'

import { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Box, Breakpoint, useBreakpoint } from '@carnegie/duplo'

import { observer } from 'mobx-react-lite'

import { ObservableTableInstrument, useTableInstruments } from '../useTableInstruments'
import { TableOverviewFooter, TableOverviewMobileFooter } from './TableOverviewFooter'
import { TableOverviewMobileRow } from './TableOverviewMobileRow'
import { TableOverviewRow } from './TableOverviewRow'

type TableOverviewProps = {
  holdingsInstruments: InstrumentPosition[]
  showPercentage: boolean
  isFund?: boolean
  showMoreColumns: boolean
  tableSortStorageKey: string
}

export type TableOverviewColumnId =
  | 'expander-button'
  | 'name'
  | 'quantity'
  | 'priceChangeOneDay'
  | 'priceChangeToday'
  | 'today'
  | 'lastPrice'
  | 'marketValue'
  | 'unrealized'
  | 'unrealizedOrUnrealizedRatio'
  | 'averageAcquisitionPrice'
  | 'acquisitionCost'
  | 'button'

export const TableOverview = observer(function TableOverview({
  holdingsInstruments,
  showPercentage,
  isFund = false,
  showMoreColumns,
  tableSortStorageKey,
}: TableOverviewProps) {
  const { t } = useTranslation()
  const breakpoint = useBreakpoint()
  const isExtraSmallScreen = breakpoint <= Breakpoint.Xs

  const { account: selectedAccount } = useSelectedHoldingAccount()
  const navigate = useNavigate()

  const { instruments, error, refetch } = useTableInstruments(holdingsInstruments)

  const addSuffix = useCallback(
    (text: string, suffix = '%') => (showMoreColumns ? `${text} ${suffix}` : text),
    [showMoreColumns]
  )

  // Memoize totals with early return for empty data
  const totals = useMemo(() => {
    const safeInstruments = instruments?.filter(Boolean) ?? []
    return {
      totalPerformanceToday: sum(
        isFund ? 'priceChangeOneDay' : 'priceChangeToday',
        safeInstruments.filter((instrument) =>
          isFund ? instrument.priceChangeOneDay != null : instrument.priceChangeToday != null
        )
      ),
      totalMarketValues: sum(
        'marketValue',
        safeInstruments.filter((instrument) => instrument.marketValue != null)
      ),
      totalUnrealized: sum(
        'unrealized',
        safeInstruments.filter((instrument) => instrument.unrealized != null)
      ),
      totalAcquisitionCost: sum(
        'amount',
        safeInstruments.map((x) => x.acquisitionCost).filter((cost): cost is NonNullable<typeof cost> => cost != null)
      ),
    }
  }, [instruments, isFund])

  const navigateToTransactions = useCallback(
    (instrument: ObservableTableInstrument) => {
      navigate(
        `/overview/transactions/${selectedAccount?.id}?instruments=${JSON.stringify([instrument.key])}&from=2005-01-01`
      )
    },
    [navigate, selectedAccount?.id]
  )

  useEffect(() => {
    refetch()
  }, [holdingsInstruments, refetch])

  const showExpanderColumn = useMemo(() => instruments?.some((instrument) => instrument.hasDetails), [instruments])

  const columns = useMemo(() => {
    const cols: NonNullable<
      Parameters<typeof SmartTable<ObservableTableInstrument, TableOverviewColumnId>>[0]['columns']
    > = [
      {
        id: 'expander-button',
        visible: showExpanderColumn,
        width: 'auto',
      },
      {
        id: 'name',
        width: showMoreColumns ? 'minmax(0,4fr)' : 'minmax(0,2fr)',
        renderHeader: () => t('Namn'),
        sortBy: ({ row }) => row.name,
      },
      {
        id: 'quantity',
        width: '1fr',
        align: 'right' as const,
        renderHeader: () => t('Antal'),
        sortBy: ({ row }) => row.quantity,
      },
      {
        visible: !!showMoreColumns,
        id: (isFund ? 'priceChangeOneDay' : 'priceChangeToday') as TableOverviewColumnId,
        width: '1fr',
        align: 'right' as const,
        renderHeader: () => (isFund ? t('1 dag') + ' +/-' : t('Idag') + ' +/-'),
        sortBy: ({ row }) => (isFund ? row.priceChangeOneDay : row.priceChangeToday),
      },
      {
        id: 'today',
        width: '1fr',
        align: 'right' as const,
        renderHeader: () => {
          if (isExtraSmallScreen) {
            return isFund ? t('1 dag') : t('Idag')
          }
          return isFund ? addSuffix(t('1 dag')) : addSuffix(t('Idag'))
        },
        sortBy: ({ row }) => {
          if (showPercentage) {
            return isFund ? row.priceChangeOneDayRatio : row.priceChangeTodayRatio
          }
          return isFund ? row.priceChangeOneDay : row.priceChangeToday
        },
      },
      {
        id: 'lastPrice',
        width: '1fr',
        align: 'right' as const,
        renderHeader: () => t('Senast'),
        sortBy: ({ row }) => row.lastPrice,
      },
      {
        id: 'marketValue',
        width: '1fr',
        align: 'right' as const,
        renderHeader: () => t('Marknadsvärde'),
        sortBy: ({ row }) => row.marketValue,
      },
      {
        visible: !!showMoreColumns,
        id: 'unrealized',
        width: '1fr',
        align: 'right' as const,
        renderHeader: () => addSuffix(t('Orealiserat'), '+/-'),
        sortBy: ({ row }) => row.unrealized,
      },
      {
        id: 'unrealizedOrUnrealizedRatio',
        width: '1fr',
        align: 'right' as const,
        renderHeader: () => addSuffix(t('Orealiserat')),
        sortBy: ({ row }) => (showPercentage ? row.unrealizedRatio ?? 0 : row.unrealized ?? 0),
      },
      {
        visible: !!showMoreColumns,
        id: 'averageAcquisitionPrice',
        width: '1fr',
        align: 'right' as const,
        renderHeader: () => t('Ansk. kurs'),
        sortBy: ({ row }) => row.averageAcquisitionPrice?.amount,
      },
      {
        visible: !!showMoreColumns,
        id: 'acquisitionCost',
        width: '1fr',
        align: 'right' as const,
        renderHeader: () => t('Ansk. värde'),
        sortBy: ({ row }) => row.acquisitionCost?.amount,
      },
      {
        id: 'button',
        width: '48px',
      },
    ]
    return cols
  }, [t, showMoreColumns, isFund, isExtraSmallScreen, addSuffix, showPercentage, showExpanderColumn])

  const renderTableRow = useCallback(
    ({ row: instrument, columns: tableColumns }) => {
      return isExtraSmallScreen ? (
        <TableOverviewMobileRow
          key={instrument.key}
          instrument={instrument}
          accountCurrencyCode={selectedAccount?.currencyCode}
          showPercentage={showPercentage}
          isFund={isFund}
          transactionOnClick={() => navigateToTransactions(instrument)}
        />
      ) : (
        <TableOverviewRow
          columns={tableColumns}
          key={instrument.key}
          instrument={instrument}
          accountCurrencyCode={selectedAccount?.currencyCode}
          showPercentage={showPercentage}
          transactionOnClick={() => navigateToTransactions(instrument)}
          isFund={isFund}
          showMoreColumns={showMoreColumns}
        />
      )
    },
    [isExtraSmallScreen, selectedAccount?.currencyCode, showPercentage, isFund, navigateToTransactions, showMoreColumns]
  )

  const renderFooter = useCallback(
    ({ columns: footerColumns }) => {
      return isExtraSmallScreen ? (
        <TableOverviewMobileFooter
          totalPerformanceToday={totals.totalPerformanceToday}
          totalMarketValues={totals.totalMarketValues}
          totalUnrealized={totals.totalUnrealized}
          showPercentage={showPercentage}
        />
      ) : (
        <TableOverviewFooter
          columns={footerColumns}
          totalPerformanceToday={totals.totalPerformanceToday}
          totalMarketValues={totals.totalMarketValues}
          totalUnrealized={totals.totalUnrealized}
          totalAcquisitionCost={totals.totalAcquisitionCost}
          showPercentage={showPercentage}
          showMoreColumns={showMoreColumns}
        />
      )
    },
    [isExtraSmallScreen, totals, showPercentage, showMoreColumns]
  )

  return (
    <Box>
      <SkeletonLoader
        error={error && !instruments}
        height={300}
        p={16}
        loading={!instruments}
        noDataLoaded={instruments?.length === 0}
      >
        {() => (
          <SmartTable<ObservableTableInstrument, TableOverviewColumnId>
            renderMode={isExtraSmallScreen ? 'custom' : 'table'}
            sessionStorageKey={tableSortStorageKey}
            defaultSortBy="name"
            data={instruments}
            columns={columns}
            renderRow={renderTableRow}
            renderFooterRow={renderFooter}
            rowKey={(row) => row.instrumentIdCarnegie}
          />
        )}
      </SkeletonLoader>
    </Box>
  )
})

TableOverview.displayName = 'TableOverview'
