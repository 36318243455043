import { InfrontInstrument } from '@common/api/response'
import { InfrontChartPeriod } from '@common/hooks/infront/infrontChartPeriod'
import { useInfrontChart } from '@common/hooks/infront/widgets/useInfrontChart'
import { rgbAlpha } from '@common/utils/colorUtils'
import { css } from '@emotion/react'
import styled from '@emotion/styled'

import React, { useCallback, useEffect, useRef } from 'react'

import { SkeletonRect, colors, md } from '@carnegie/duplo'

import { observer } from 'mobx-react-lite'
import { debounce } from 'throttle-debounce'

import { CommonInstrumentType } from './useCommonInstrumentType'

type Props = {
  infrontInstrument: InfrontInstrument
  period: InfrontChartPeriod
  height?: number
  customOptions?: object
  instrumentType: CommonInstrumentType
}

/**
 * Hur får jag reda på när den laddas? Svar: widgetStateCallback
 * Går det att ställa in chart type?
 */

const TOOLTIP_WIDTH = 160
const TOOLTIP_HEIGHT = 75

// For testing the infront chart without our customizations
const VANILLA_MODE = false

export const InstrumentChart = observer(
  ({ infrontInstrument, period, height, customOptions, instrumentType = 'unknown' }: Props) => {
    const chartRef = useRef<HTMLDivElement>(null)

    if (!period) {
      throw new Error('Period had an invalid value, the value was: ' + period)
    }

    const chartOptions = {
      ...customOptions,
      showVolume: instrumentType !== 'index',
    }

    const { containerId, widget, widgetLoaded } = useInfrontChart(
      infrontInstrument,
      period,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      chartOptions as any
    )

    const callback = useCallback(() => {
      if (widget) widget.resizeWidget()
    }, [widget])

    useDebouncedeWindowWidth(callback)

    const handleChartTooltipPosition = (e) => {
      const { currentTarget, clientX, clientY } = e
      const tooltip: HTMLDivElement = currentTarget.querySelector('.cell-chart__label-wrapper')
      if (!tooltip || !tooltip.style) return

      const { x, y, height: rectHeight, width } = chartRef.current.getBoundingClientRect()
      const left = clientX - x
      const top = clientY - y

      // We only want to show the tooltip when it's above the chart, not the chart controls.
      if (top > rectHeight - 70) {
        hideElement(tooltip)
        return
      }

      let offsetTop = 0,
        offsetLeft = 0
      if (top + TOOLTIP_HEIGHT > rectHeight) {
        offsetTop = -TOOLTIP_HEIGHT
      }
      if (left + TOOLTIP_WIDTH > width) {
        offsetLeft = -TOOLTIP_WIDTH
      }

      moveElement({ element: tooltip, left, top, offsetTop, offsetLeft })
    }

    const onMouseMove = (e) => handleChartTooltipPosition(e)

    const onMouseLeave = () => {
      const tooltip = document.querySelector<HTMLDivElement>('.cell-chart__label-wrapper')
      if (tooltip && tooltip.style) {
        hideElement(tooltip)
      }
    }

    const onTouchStart = (e) => {
      e.preventDefault()
      handleChartTooltipPosition(e)
    }

    return (
      <>
        <svg style={{ height: 0, display: 'block' }}>
          <defs>
            <linearGradient x1="0" y1="0" x2="0" y2="1" id="carnegieLinearGradient">
              <stop offset="0" stopColor="#7cb5ec" stopOpacity="1"></stop>
              <stop offset="1" stopColor="rgb(124,181,236)" stopOpacity="0"></stop>
            </linearGradient>
          </defs>
        </svg>
        <Wrapper height={height}>
          <ChartContainer
            css={{ overflow: 'hidden' }}
            height={height}
            id={containerId}
            ref={chartRef}
            onMouseMove={VANILLA_MODE ? () => {} : onMouseMove}
            onMouseLeave={VANILLA_MODE ? () => {} : onMouseLeave}
            onTouchStart={VANILLA_MODE ? () => {} : onTouchStart}
          >
            {!widgetLoaded && <SkeletonRect width="100%" height="100%" />}
          </ChartContainer>
        </Wrapper>
      </>
    )
  }
)
InstrumentChart.displayName = 'InstrumentChart'

function useDebouncedeWindowWidth(callback: () => void, delay = 700) {
  useEffect(() => {
    const handleResize = () => {
      callback()
    }
    const debouncedHandleResize = debounce(delay, handleResize)
    window.addEventListener('resize', debouncedHandleResize)
    return () => {
      window.removeEventListener('resize', debouncedHandleResize)
    }
  }, [delay, callback])
}

function hideElement(element) {
  element.style.transition = `all .15s ease`
  element.style.opacity = '0'
}

type MoveElementProps = {
  element: HTMLElement
  left: number
  top: number
  offsetLeft?: number
  offsetTop?: number
}

const moveElement = ({ element, left, top, offsetLeft = 0, offsetTop = 0 }: MoveElementProps) => {
  element.style.transition = `opacity .5s ease`
  element.style.opacity = '1'
  element.style.left = `${left + offsetLeft}px`
  element.style.top = `${top + offsetTop}px`
}

const Wrapper = (props: React.HTMLAttributes<HTMLDivElement> & { height: number }) => {
  return (
    <div
      css={css`
        height: 188px;
        ${md(`height: ${props.height || 292}px`)};
        position: relative;
      `}
      {...props}
    >
      {props.children}
    </div>
  )
}
Wrapper.displayName = 'Wrapper'

const ChartContainer = VANILLA_MODE
  ? styled.div`
      height: 188px;
      ${({ height }: { height: number }) => md(`height: ${height || 292}px`)};
      max-width: 100%;
    `
  : styled.div`
      height: 188px;
      ${({ height }: { height: number }) => md(`height: ${height || 292}px`)};
      max-width: 100%;

      .cell-chart__label-wrapper {
        position: absolute;
        border-radius: 4px;
        opacity: 0;
        width: ${TOOLTIP_WIDTH}px !important;
        height: ${TOOLTIP_HEIGHT};
      }
      .cell-chart__label-element {
        display: flex !important;
        flex-direction: column !important;
      }
      .cell-chart__label-element__label button {
        display: none;
      }

      .cell-chart__label-element__label__text_box {
        font-size: 12px !important;
        letter-spacing: 0.4px;
        // Fixing some Infront styling issues, using too long names will not fit the box
        // so we add ellipsis, we also remove the bullet point and the padding around the element
        padding: 0px;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      // Bullet point next to name in tooltip (we do not want it)
      .cell-chart__series-label-wrapper
        > .cell-chart__label-element:first-of-type
        > .cell-chart__label-element__label:before {
        display: none;
      }

      .cell-chart__label-element__label__color_box {
        background: none !important;
      }

      .cell-chart-default .highcharts-background {
        fill: transparent !important;
      }

      .cell-chart-default .highcharts-graph {
        stroke: ${colors.astral} !important;
      }

      .cell-chart-default .highcharts-xaxis-grid .highcharts-grid-line {
        stroke: transparent !important;
      }

      .cell-chart-default .highcharts-plot-band {
        fill: transparent !important;
      }

      .cell-chart-default-series--volume .highcharts-point {
        fill: ${rgbAlpha(colors.bunker[100], 0.2)};
        opacity: 1 !important;
        width: 4px !important;
      }

      .highcharts-navigator rect.highcharts-navigator-mask-inside {
        fill: rgba(133, 179, 229, 0.15) !important;
      }

      .cell-chart-default .highcharts-navigator-series .highcharts-graph {
        stroke: ${colors.astral} !important;
      }
      .cell-chart-default .highcharts-axis-labels text {
        fill: ${colors.bunker[100]} !important;
      }
      .cell-chart-default .highcharts-yaxis-grid .highcharts-grid-line {
        stroke: #e5e5e5 !important;
        stroke-width: 0.5px;
      }
      .cell-chart__label-wrapper {
        float: none;
        background: rgba(248, 248, 248, 0.9);
        border: 1px solid rgb(172, 203, 225);
        height: 72.84px;
        width: 112px;
        padding: ${4}px;
      }

      .cell-chart__series-label-wrapper > .cell-chart__label-element:first-of-type > .cell-chart__label-element__label {
        float: none;
        font-family: 'Roboto';
        font-size: 12px;
        letter-spacing: 0.4px;
        font-weight: 500;
        height: 15px;
        letter-spacing: 0px;
        margin-bottom: ${4}px;
        padding: 0;
        &:before {
          content: '•';
          color: ${colors.regentStreetBlue.main};
          margin-right: ${4}px;
        }
      }

      .cell-chart__label-element__values__element:not(:empty) {
        margin-left: 2px;
        margin-right: 4px;
        color: ${colors.bunker.main};
        font-family: 'Roboto';
        font-size: 12px !important;
        letter-spacing: 0.4px;
        height: 14px;
        letter-spacing: 0px;
        margin-bottom: ${4}px;
      }

      .cell-chart__label-element__values__element:not(:empty) {
        margin-left: 0;
        margin-right: 0;
      }

      .cell-chart__label-element__values {
        display: flex;
        flex-direction: column-reverse;
        float: none;
        padding: 0;
      }

      .cell-chart__label-element__label {
        background-color: transparent !important;
        color: ${colors.bunker.main} !important;
      }

      .highcharts-area {
        fill: url(#carnegieLinearGradient) !important;
        stroke: none;
      }
      .cell-chart-default .highcharts-navigator-series .highcharts-area {
        stroke: ${rgbAlpha(colors.bunker[100], 0.4)}!important;
      }

      .highcharts-series-group path {
        stroke-width: 1 !important;
      }

      .highcharts-color-0 {
        fill: ${colors.astral};
        stroke: ${colors.astral};
      }
    `
ChartContainer.displayName = 'ChartContainer'
