import { FormattedNumber } from '@common/components/FormattedNumber'

import { useTranslation } from 'react-i18next'

import { formatNumber } from '@carnegie/digital-channels-frontend'
import { Box, FlexCol, MobileTableRow, Text } from '@carnegie/duplo'

import { observer } from 'mobx-react-lite'

import { InstrumentNameMobile } from '../InstrumentName'
import { Currency } from '../useCurrencyPositions'

type Props = {
  currency: Currency
}

export const CurrencyPositionsMobile = observer(function CurrencyPositionsMobile({ currency }: Props) {
  const { t } = useTranslation()
  const { currencyName, quantity, marketValue, fxRate } = currency

  //https://dev.azure.com/carnegieinvestmentbank/CarnegieIT/_workitems/edit/74700
  if (marketValue === null) return <Box />

  return (
    <MobileTableRow
      divider
      title={<InstrumentNameMobile isCurrency isoCountryCode={currencyName} name={currencyName} />}
    >
      <FlexCol flex={1} alignItems="flex-start">
        <Text variant="label1">{t('Saldo')}</Text>
        <FormattedNumber variant="subtitle2" value={quantity} unit={currencyName} decimals={2} />
      </FlexCol>
      <FlexCol flex={1} alignItems="flex-start">
        <Text variant="label1">{t('Saldo')} (SEK)</Text>
        <FormattedNumber variant="subtitle2" unit="SEK" value={marketValue} decimals={2} />
      </FlexCol>
      <FlexCol flex={1} alignItems="flex-end">
        <Text variant="label1">{t('Valutakurs')}</Text>
        <Text variant="body2" textAlign="right">
          {formatNumber(fxRate, { decimals: 2 })}
        </Text>
      </FlexCol>
    </MobileTableRow>
  )
})

CurrencyPositionsMobile.displayName = 'CurrencyPositionsMobile'
