import { ExpandTableButton, ExpandableTr } from '@common/components/SmartTable'
import { fireTrackEvent } from '@common/utils/analyticsEvent'

import { useCallback, useMemo, useState } from 'react'

import { Box, Breakpoint, Td, Text, useBreakpoint } from '@carnegie/duplo'

import { ObservableTableInstrument } from '../useTableInstruments'
import { TableOverviewColumnId } from './TableOverview'
import { TableOverviewMobileRow } from './TableOverviewMobileRow'
import { TableOverviewRow } from './TableOverviewRow'

type TableOverviewPortfolioRowProps = {
  instruments: ObservableTableInstrument[]
  accountCurrencyCode: string
  showPercentage: boolean
  transactionOnClick?: () => void
  isFund: boolean
  showMoreColumns: boolean
  columns: { id?: TableOverviewColumnId }[]
  modelPortfolio: string
}

/**
 * Renders a portfolio group row that can be expanded to show individual instrument rows
 * Handles both desktop and mobile views with different row components
 */
export const TableOverviewPortfolioRow = function TableOverviewPortfolioRow({
  instruments,
  accountCurrencyCode,
  showPercentage,
  transactionOnClick,
  isFund,
  showMoreColumns,
  columns,
  modelPortfolio,
}: TableOverviewPortfolioRowProps) {
  const [expanded, setExpanded] = useState(false)
  const breakpoint = useBreakpoint()
  const isExtraSmallScreen = breakpoint <= Breakpoint.Xs

  const hasExpanderColumn = useMemo(() => columns.some((columnDef) => columnDef.id === 'expander-button'), [columns])

  const handleExpand = useCallback(() => {
    if (!expanded) {
      fireTrackEvent('Holdings', 'expand_portfolio_group')
    }
    setExpanded(!expanded)
  }, [expanded])

  // Memoize empty cells array to prevent recreation on each render
  const emptyCells = useMemo(() => {
    const count = columns.length - (hasExpanderColumn ? 2 : 1)
    return Array(count).fill(null)
  }, [columns.length, hasExpanderColumn])

  // Memoize instrument rows to prevent unnecessary re-renders
  const instrumentRows = useMemo(() => {
    // Remove the expanded check since we want to show instruments when hasDetails is true
    const hasDetailsInstruments = instruments.some((inst) => inst?.hasDetails)
    if (!hasDetailsInstruments && !expanded) return null

    return isExtraSmallScreen ? (
      <>
        {instruments.map((instrument) => (
          <TableOverviewMobileRow
            key={instrument.key}
            instrument={instrument}
            accountCurrencyCode={accountCurrencyCode}
            showPercentage={showPercentage}
            transactionOnClick={transactionOnClick}
            isFund={isFund}
          />
        ))}
      </>
    ) : (
      <>
        {instruments.map((instrument) => (
          <TableOverviewRow
            key={instrument.key}
            instrument={instrument}
            accountCurrencyCode={accountCurrencyCode}
            showPercentage={showPercentage}
            transactionOnClick={transactionOnClick}
            isFund={isFund}
            showMoreColumns={showMoreColumns}
            columns={columns}
          />
        ))}
      </>
    )
  }, [
    expanded,
    isExtraSmallScreen,
    instruments,
    accountCurrencyCode,
    showPercentage,
    transactionOnClick,
    isFund,
    showMoreColumns,
    columns,
  ])

  const hasDetailsInstruments = instruments.some((inst) => inst?.hasDetails)

  return (
    <>
      {!hasDetailsInstruments && (
        <ExpandableTr expanded={expanded}>
          {hasExpanderColumn && (
            <Td>
              <ExpandTableButton expanded={expanded} onClick={handleExpand} />
            </Td>
          )}
          <Td>
            <Box>
              <Text variant="subtitle2">{modelPortfolio}</Text>
            </Box>
          </Td>
          {emptyCells.map((_, index) => (
            <Td key={index} />
          ))}
        </ExpandableTr>
      )}
      {instrumentRows}
    </>
  )
}

TableOverviewPortfolioRow.displayName = 'TableOverviewPortfolioRow'
