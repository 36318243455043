import { createContext, useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import { useAuth as useCurityAuth } from '@carnegie/curity-auth'

import { RootStore } from './rootStore'

/**
 * @deprecated Use `useNavigate` from 'react-router-dom' instead.
 * Example:
 * ```
 * import { useNavigate } from 'react-router-dom';
 *
 * Changed history.replace(relativePath) to navigate(relativePath, { replace: true }).
 *     navigate('/new-path');
 *     // For replace: navigate('/new-path', { replace: true });
 *     // With state: navigate('/new-path', { state: { someData: 'value' } });
 *   };
 *
 * ```
 */
export const useNavigation = () => {
  // useDrawerStore() needs this to be available, so don't remove it.

  const storeRoot = useRootStore()
  const navigate = useNavigate()

  // Set the navigate function in the navigationStore
  storeRoot.navigationStore.setNavigate(navigate)

  return storeRoot.navigationStore
}
export const useApi = () => useRootStore().api

export const useAuth = () => {
  const storeRoot = useRootStore()

  const authStore = storeRoot.authStore

  // try/catch is needed because the curity auth store is not available on "public pages" (/login/support etc.) where we don't want to throw an error
  try {
    // Because we can only consume curity using hooks we bridge the worlds (hooks vs classes) here
    const curityAuthStore = useCurityAuth()

    // This is needed the bridge the "hooks world" with the mobx "class world"
    authStore.setCurityAuthStore(curityAuthStore)
  } catch (err) {
    console.log(err)
  }

  return authStore
}

export const useLocalStorage = () => useRootStore().localStorage
export const useSessionStorage = () => useRootStore().sessionStorage
export const useWatchlistsStore = () => useRootStore().watchlistStore
export const useHttp = () => useRootStore().http

/**
 * ## ⚠️IMPORTANT⚠️
 * ### Requires wrapping component in MobX `observer(...)`
 */
// eslint-disable-next-line @carnegie/duplo/no-untyped-components
export const useInfrontStore = (mustBeLoaded = false) => {
  const store = useRootStore().infrontStore
  if (mustBeLoaded) {
    return store.hasLoaded ? store : undefined
  }
  return store
}

export const rootStore = new RootStore()

const RootStoreContext = createContext<RootStore>(rootStore)

export function useRootStore(): RootStore {
  const store = useContext(RootStoreContext)
  return store
}
