import { TransferResponse } from '@common/api/response'
import { WebSocketCallback, WebSocketMessage, useWebSocket } from '@common/hooks/useWebSocket'
import { fireTrackEvent } from '@common/utils/analyticsEvent'

import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Badge,
  Box,
  Breakpoint,
  Button,
  FlexRow,
  Heading6,
  LoadingIcon,
  Modal,
  Paragraph,
  Spacer,
  Text,
  useBreakpoint,
} from '@carnegie/duplo'

import { TransferState } from '@/drawers/transfersDrawer/TransferPageContext'
import BankIdLogoSvg from '@/pages/login/bankid.svg'

import QRCode from 'qrcode'

type BankIdSignModalProps = {
  transferResponse: TransferResponse
  openModal: boolean
  onClosing: () => void
  setParentDisabledForm: (value: boolean) => void
  setRegisteringTransfer: (transferState: TransferState) => void
}

type BankIDSignBannerTypes = 'timeout' | 'canceled' | 'IncorrectUser' | 'failed'

let canvasRenderTrials = 3

export const BankIdSignModal = ({
  transferResponse,
  openModal,
  onClosing,
  setParentDisabledForm,
  setRegisteringTransfer,
}: BankIdSignModalProps) => {
  const { t } = useTranslation()
  const breakpoint = useBreakpoint()
  const isExtraSmallScreen = breakpoint < Breakpoint.Small

  const canvasRef = useRef(null)
  const [qrCodeValue, setQrCodeValue] = useState<string>(null)
  const [bankIdSignBannerType, setBankIdSignBannerType] = useState<BankIDSignBannerTypes>(null)
  const [showLoadingSpinner, setShowLoadingSpinner] = useState(true)

  const messageHandler = useCallback<WebSocketCallback>(
    (message: WebSocketMessage) => {
      console.log(message.type, message)
      if (!message?.payload || !message?.type) return
      switch (message.type) {
        case 'TRANSFER_ORDER_BANKID_SIGNING_PENDING':
          setQrCodeValue(String(message.payload))
          break
        case 'TRANSFER_ORDER_BANKID_SIGNING_LOADING':
          setShowLoadingSpinner(true)
          break
        case 'TRANSFER_ORDER_BANKID_SIGNING_COMPLETED':
        case 'TRANSFER_ORDER_PAYMENT_CREATED':
          setShowLoadingSpinner(false)
          setRegisteringTransfer('success') // Show banner on parent
          onClosing()
          break
        case 'TRANSFER_ORDER_BANKID_SIGNING_TIMEOUT':
          setShowLoadingSpinner(false)
          setBankIdSignBannerType('timeout')
          break
        case 'TRANSFER_ORDER_BANKID_SIGNING_CANCELED':
          setShowLoadingSpinner(false)
          setBankIdSignBannerType('canceled')
          break
        case 'TRANSFER_ORDER_BANKID_SIGNING_INCORRECTUSER':
          setShowLoadingSpinner(false)
          setBankIdSignBannerType('IncorrectUser')
          break
        case 'TRANSFER_ORDER_BANKID_SIGNING_FAILED':
          while (canvasRef.current?.firstChild) {
            canvasRef.current.removeChild(canvasRef.current.firstChild) // Reset canvas container
          }
          setShowLoadingSpinner(false)
          setBankIdSignBannerType('failed')
          break
        case 'TRANSFER_ORDER_BANKID_SIGNING_ERROR':
          setShowLoadingSpinner(false)
          setRegisteringTransfer('error') // Show banner on parent
          onClosing()
          setParentDisabledForm(false)
          break
      }
    },
    [onClosing, setParentDisabledForm, setRegisteringTransfer]
  )

  useWebSocket(messageHandler)

  const generateQRCode = useCallback(async () => {
    try {
      if (!openModal || showLoadingSpinner || !qrCodeValue || !canvasRef?.current) return

      const canvasElement = await QRCode.toCanvas(qrCodeValue, { width: 200, errorCorrectionLevel: 'low' })
      if (!canvasElement) throw new Error('No canvas element given.')

      setBankIdSignBannerType(null)

      while (canvasRef.current?.firstChild) {
        canvasRef.current.removeChild(canvasRef.current.firstChild) // Reset canvas container
      }

      canvasRef.current.appendChild(canvasElement)
    } catch (error) {
      if (canvasRenderTrials > 0) {
        canvasRenderTrials--
        generateQRCode() // Retry
      } else {
        setBankIdSignBannerType('failed')
        console.error(`Error when generating QR-code: ${error}`)
      }
    }
  }, [openModal, qrCodeValue, showLoadingSpinner])

  useEffect(() => {
    if (!transferResponse?.qrData) return

    setBankIdSignBannerType(null)
    setShowLoadingSpinner(false)
    setQrCodeValue(transferResponse.qrData)
  }, [transferResponse?.qrData])

  useEffect(() => {
    generateQRCode()
  }, [generateQRCode, qrCodeValue])

  return (
    <Modal
      open={openModal}
      disableBackdropEscClose
      size="xs"
      onClose={() => {
        setRegisteringTransfer(null)
        setParentDisabledForm(false)
        onClosing()
      }}
      title={t('Signera din överföring')}
    >
      <Box textAlign="center">
        <Box justifyContent="center" display="flex" pb={showLoadingSpinner ? 16 : 0}>
          <BankIdLogoSvg />
        </Box>
        {bankIdSignBannerType != null && <Heading6 p={16}>{t('Signering misslyckades')}</Heading6>}
        {(bankIdSignBannerType === 'IncorrectUser' || bankIdSignBannerType === 'failed') && (
          <Paragraph variant="body1">{t('Signeringen misslyckades, vänligen försök igen.')}</Paragraph>
        )}
        {bankIdSignBannerType === 'canceled' && (
          <Paragraph variant="body1">{t('Signeringen avbröts, vänligen försök igen.')}</Paragraph>
        )}
        {bankIdSignBannerType === 'timeout' && (
          <Paragraph variant="support2">{t('Signeringen påbörjades inte i tid, vänligen försök igen.')}</Paragraph>
        )}
        {!bankIdSignBannerType && (
          <Paragraph variant="body1">
            <Box justifyContent="center" display="flex" pb={16}>
              {showLoadingSpinner ? <LoadingIcon /> : <div ref={canvasRef}></div>}
            </Box>
            <Box justifyContent="center" display="grid">
              {showLoadingSpinner ? (
                <>{t('Signera uttaget i BankID-appen.')}</>
              ) : (
                <>
                  <FlexRow alignItems="flex-start">
                    <Badge size="medium" mr={8}>
                      1
                    </Badge>
                    {t('Öppna BankID-appen på din enhet')}
                  </FlexRow>
                  <Spacer height={8} />
                  <FlexRow alignItems="flex-start">
                    <Badge size="medium" mr={8}>
                      2
                    </Badge>
                    {t('Tryck på “Scanna QR-kod”')}
                  </FlexRow>
                  <Spacer height={8} />
                  <FlexRow alignItems="flex-start">
                    <Badge size="medium" mr={8}>
                      3
                    </Badge>
                    {t('Scanna koden med kameran')}
                  </FlexRow>
                  <Spacer height={16} />
                  <Text variant="subtitle1">{t('Eller')}</Text>
                </>
              )}
            </Box>
          </Paragraph>
        )}
        <FlexRow mt={16} width="full" justifyContent="center">
          {transferResponse && !bankIdSignBannerType && !showLoadingSpinner && (
            <Button
              mb={isExtraSmallScreen ? 16 : 0}
              onClick={() => {
                fireTrackEvent('Transaction', 'open_bankid_sign_on_this_machine')
                setShowLoadingSpinner(true)
                window.location.replace(
                  isExtraSmallScreen
                    ? transferResponse.bankIdSignUriAppUniversal
                    : transferResponse.bankIdSignUriDesktop
                )
              }}
              width={'2/3'}
              variant="secondary"
              size="medium"
            >
              <Text variant="button2">{t('BankID på den här enheten')}</Text>
            </Button>
          )}
        </FlexRow>
      </Box>
    </Modal>
  )
}
