import { AccountSelectListItem } from '@common/components/AccountSelect'
import { DropdownButton } from '@common/components/DropdownButton'
import { FavoriteStar } from '@common/components/FavoriteStar'
import { useScrollToTop } from '@common/components/ScrollToTop'
import { SelectedAccountIdProvider } from '@common/components/SelectedAccountProvider'
import { SystemMessageList } from '@common/components/systemMessages'
import { useAccountPerformanceSeries } from '@common/hooks/useAccountPerformanceSeries'
import { useAccountUserSettings } from '@common/hooks/useAccountUserSettings'
import { useHoldingAccounts, useSelectedHoldingAccount } from '@common/hooks/useHoldings'
import { useLocationQuery } from '@common/hooks/useLocationQuery'
import { useSelectedAccountIdParams } from '@common/hooks/useSelectedAccountIdParams'
import { useApi, useLocalStorage } from '@common/stores/store'
import { fireTrackEvent } from '@common/utils/analyticsEvent'
import { compareBooleans } from '@common/utils/compareBoolean'
import * as Sentry from '@sentry/react'

import { ReactNode, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { formatDate } from '@carnegie/digital-channels-frontend'
import {
  BackLink,
  Badge,
  Box,
  Breakpoint,
  Button,
  ButtonIcon,
  ButtonLinkExternal,
  ButtonSwitch,
  Checkbox,
  FlexRow,
  GridContainer,
  GridItem,
  Heading3,
  Icon,
  IconChevronDown,
  IconChevronUp,
  IconDownload,
  IconInfoOutlined,
  IconRedo,
  LinkButton,
  LoadingIcon,
  MenuItem,
  Option,
  Select,
  SkeletonRect,
  Spacer,
  Tooltip,
  useBreakpoint,
} from '@carnegie/duplo'

import { MyOfferings } from '@/pages/overview/home/MyOfferings'

import { format } from 'date-fns'
import { observer } from 'mobx-react-lite'

import { AccountAllocation } from './AccountAllocation'
import { AccountPerformance } from './AccountPerformance'
import { AccountShortcuts } from './AccountShortcuts'
import { AccountSummarySegment } from './AccountSummary/AccountSummary'
import { SectionTitle } from './SectionTitle'
import { Allocations } from './accountHoldings/Allocations'
import { GroupName } from './accountHoldings/GroupName'
import { PortfolioManagementView } from './accountHoldings/PortfolioManagementView'
import { useHoldingsGroup } from './accountHoldings/useHoldingsGroup'
import { CurrencyPositions } from './holdingsTable/TableCurrencies/CurrencyPositions'

enum DisplayUnit {
  Percentage = 'PERCENTAGE',
  Amount = 'AMOUNT',
}

const items = [
  { value: DisplayUnit.Percentage, label: '%' },
  { value: DisplayUnit.Amount, label: '+/-' },
]

const AccountPage = observer(function AccountPage() {
  const { t, i18n } = useTranslation()
  const breakpoint = useBreakpoint()
  const isExtraSmall = breakpoint <= Breakpoint.Xs
  const isMobile = breakpoint < Breakpoint.Medium
  const isTablet = breakpoint < Breakpoint.Large
  const navigate = useNavigate()
  const accountId = useSelectedAccountIdParams()
  const query = useLocationQuery<{ returnroute: string }>()
  const api = useApi()

  const { setAccountUserSettings } = useAccountUserSettings()

  const returnRoute = query?.returnroute

  const accountPerformance = useAccountPerformanceSeries(accountId)

  const { accounts } = useHoldingAccounts()
  const { account: selectedHoldingAccount } = useSelectedHoldingAccount()
  const selectedAccount = accounts?.find((account) => account.id === accountId)

  const [selectedUnit, setSelectedUnit] = useState(items[0].value)

  const [showMoreColumns, setShowMoreColumns] = useState(false)

  const showPercentage = selectedUnit === DisplayUnit.Percentage

  useScrollToTop()

  const holdingsExportFilename = `Holdings-${format(new Date(), 'yyyy-MM-dd--HH-mm-ss')}.xlsx`

  const unitSwitcher = (
    <ButtonSwitch
      width={80}
      options={items}
      value={selectedUnit}
      onChange={(value) => {
        setSelectedUnit(value)
        fireTrackEvent('Account', 'toggle_sek_%')
      }}
    />
  )

  const backButton = (
    <FlexRow>
      <BackLink mb={4} to={returnRoute || '/overview/home'}>
        {t('Tillbaka')}
      </BackLink>
    </FlexRow>
  )

  const headerDropdown = (
    <DropdownButton
      button={(open) => {
        return (
          <Button variant="tertiary" size="large" p={4}>
            <FlexRow alignItems="center">
              <Box mr={8}>
                {selectedAccount?.accountType?.description ? (
                  <Tooltip title={t(selectedAccount?.accountType?.description)}>
                    <Badge size="large">{selectedAccount?.accountType?.shortName}</Badge>
                  </Tooltip>
                ) : (
                  <Badge size="large">{selectedAccount?.accountType?.shortName}</Badge>
                )}
              </Box>
              <Heading3 mr={4}>{selectedAccount?.number}</Heading3>
              <Icon size={32} icon={open ? IconChevronUp : IconChevronDown} />
            </FlexRow>
          </Button>
        )
      }}
    >
      {(() => {
        const accountSelectItems = (() => {
          try {
            if (accounts && !Array.isArray(accounts)) {
              throw new Error(`Expected accounts to be an array, got ${typeof accounts}: ${JSON.stringify(accounts)}`)
            }

            return (accounts || [])
              .toSorted((a, b) =>
                compareBooleans(a?.userSettings?.isFavorite ?? false, b?.userSettings?.isFavorite ?? false)
              )
              .map((account, index) =>
                account ? (
                  <MenuItem
                    p={0}
                    display="flex"
                    onClick={() => {
                      const queryParam = returnRoute ? `returnroute=${returnRoute}` : ''
                      navigate(`/accounts/${account?.id}?${queryParam}`, {
                        replace: true,
                      })
                    }}
                    key={account.id || index}
                    value={account.id}
                    flexDirection={'row'}
                  >
                    <AccountSelectListItem
                      account={account}
                      isSelected={selectedAccount?.id === account.id}
                      divider={index < (accounts?.length || 0) - 1}
                    />
                  </MenuItem>
                ) : null
              )
              .filter(Boolean)
          } catch (error) {
            Sentry.captureException(error, {
              extra: {
                accounts: JSON.stringify(accounts),
                location: 'AccountPage accountSelectItems',
              },
            })
            console.error('Error processing accounts:', error)
            return []
          }
        })()

        const overviewLink = (
          <MenuItem
            width="100%"
            display="flex"
            onClick={() => {
              navigate(`/overview/accounts`)
            }}
            key="overviewLink"
            flexDirection="row"
            justifyContent="center"
            py={8}
            px={16}
          >
            <LinkButton size="small" title={t('Visa alla konton')}>
              {t('Visa alla konton')}
            </LinkButton>
          </MenuItem>
        )
        return [...accountSelectItems, overviewLink]
      })()}
    </DropdownButton>
  )

  let content: ReactNode = null

  const boxWithHeaderAndSystemMessage = (
    <Box spaceY={16}>
      <FlexRow justifyContent="space-between" alignItems="center">
        <FlexRow alignItems="center">
          {accounts ? headerDropdown : <SkeletonRect width={226} height={40} />}
          <Tooltip
            title={selectedAccount?.userSettings?.isFavorite ? t('Ta bort favoritmarkering') : t('Favoritmarkera')}
          >
            <Button
              onClick={() => {
                setAccountUserSettings(selectedAccount.id, { isFavorite: !selectedAccount?.userSettings?.isFavorite })
              }}
              px={4}
              variant="tertiary"
              size={'small'}
            >
              <FavoriteStar filled={selectedAccount?.userSettings?.isFavorite} size="large" />
            </Button>
          </Tooltip>
        </FlexRow>
        <AccountShortcuts selectedAccount={selectedAccount} />
      </FlexRow>
      <SystemMessageList segmentId="accounts" />
    </Box>
  )

  if (isExtraSmall) {
    content = (
      <>
        <GridItem xs={12}>
          <GridContainer spacing={0}>
            <GridItem xs={12}>
              <Box>{backButton}</Box>
            </GridItem>
            <GridItem xs={12}>{boxWithHeaderAndSystemMessage}</GridItem>
          </GridContainer>
        </GridItem>
        <Spacer height={16} />
        <GridItem xs={12}>
          <GridContainer>
            <GridItem xs={12} sm={12}>
              <AccountPerformance
                accountId={selectedAccount?.id}
                accountNumber={selectedAccount?.number}
                performanceSerie={accountPerformance?.performanceSeries}
                selectedHoldingAccount={selectedHoldingAccount}
              />
              <Spacer height={16} />
              <AccountSummarySegment
                accountId={selectedAccount?.id}
                performanceSerie={accountPerformance?.performanceSeries}
                currencyPositions={selectedHoldingAccount?.holdings?.currencies}
              />
              <MyOfferings mt={48} limit={4} minCardSize="1/3" accountIds={[selectedAccount?.id]} />
            </GridItem>
          </GridContainer>
          <Spacer height={16} />
          {selectedHoldingAccount?.holdings && (
            <GridContainer>
              <GridItem xs={12}>
                <SectionTitle title={t('Innehav')}>
                  <FlexRow space={8}>
                    <ButtonLinkExternal
                      startIcon={<ButtonIcon icon={IconDownload} />}
                      size="small"
                      width="auto"
                      variant="tertiary"
                      onClick={() => fireTrackEvent('Holdings', 'download_holdings')}
                      href={api.exportHoldingsUrl(selectedAccount?.id, i18n.language)}
                      rel="noreferrer"
                      target="_blank"
                      download={holdingsExportFilename}
                    >
                      {t('Exportera')}
                    </ButtonLinkExternal>
                    {unitSwitcher}
                  </FlexRow>
                </SectionTitle>
                <Spacer height={16} />
                <AccountHoldings showPercentage={showPercentage} />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <AccountAllocation />
              </GridItem>
            </GridContainer>
          )}
        </GridItem>
      </>
    )
  } else if (isMobile) {
    content = (
      <>
        <GridItem xs={12}>
          <GridContainer spacing={0}>
            <GridItem xs={12}>
              <Box>{backButton}</Box>
            </GridItem>
            <GridItem xs={12}>{boxWithHeaderAndSystemMessage}</GridItem>
          </GridContainer>
        </GridItem>
        <Spacer height={16} />
        <GridItem xs={12}>
          <GridContainer style={{ flexDirection: isExtraSmall ? 'column-reverse' : 'row' }}>
            <GridItem xs={12} sm={12}>
              <AccountPerformance
                accountId={selectedAccount?.id}
                accountNumber={selectedAccount?.number}
                performanceSerie={accountPerformance?.performanceSeries}
                selectedHoldingAccount={selectedHoldingAccount}
              />
              <Spacer height={16} />
              <AccountSummarySegment
                accountId={selectedAccount?.id}
                performanceSerie={accountPerformance?.performanceSeries}
                currencyPositions={selectedHoldingAccount?.holdings?.currencies}
              />
              <MyOfferings mt={48} limit={4} minCardSize="1/3" accountIds={[selectedAccount?.id]} />
            </GridItem>
            <Spacer height={16} />
          </GridContainer>
          <Spacer height={16} />
          {selectedHoldingAccount?.holdings && (
            <GridContainer>
              <GridItem xs={12}>
                <SectionTitle title={t('Innehav')}>
                  <FlexRow space={8}>
                    <ButtonLinkExternal
                      startIcon={<ButtonIcon icon={IconDownload} />}
                      size="small"
                      width="auto"
                      variant="tertiary"
                      onClick={() => fireTrackEvent('Holdings', 'download_holdings')}
                      href={api.exportHoldingsUrl(selectedAccount?.id, i18n.language)}
                      rel="noreferrer"
                      target="_blank"
                      download={holdingsExportFilename}
                    >
                      {t('Exportera')}
                    </ButtonLinkExternal>
                    {unitSwitcher}
                  </FlexRow>
                </SectionTitle>
                <Spacer height={16} />
                <AccountHoldings showPercentage={showPercentage} />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <AccountAllocation />
              </GridItem>
            </GridContainer>
          )}
        </GridItem>
      </>
    )
  } else if (isTablet) {
    content = (
      <>
        <GridItem xs={12}>
          <Box>
            {backButton}
            {boxWithHeaderAndSystemMessage}
          </Box>
        </GridItem>
        <Spacer height={32} />
        <GridItem xs={12}>
          <GridContainer>
            <GridItem xs={12} sm={12}>
              <AccountPerformance
                accountId={selectedAccount?.id}
                accountNumber={selectedAccount?.number}
                performanceSerie={accountPerformance?.performanceSeries}
                selectedHoldingAccount={selectedHoldingAccount}
              />
            </GridItem>
            <GridItem xs={12}>
              <AccountSummarySegment
                accountId={selectedAccount?.id}
                performanceSerie={accountPerformance?.performanceSeries}
                currencyPositions={selectedHoldingAccount?.holdings?.currencies}
              />
              <MyOfferings mt={48} limit={4} minCardSize="1/3" accountIds={[selectedAccount?.id]} />
            </GridItem>
            {selectedHoldingAccount?.holdings && (
              <GridItem xs={12}>
                <FlexRow justifyContent="space-between" alignItems="center">
                  <SectionTitle title={t('Innehav')} sticky>
                    <FlexRow space={8}>
                      <ButtonLinkExternal
                        startIcon={<ButtonIcon icon={IconDownload} />}
                        size="small"
                        width="auto"
                        variant="tertiary"
                        onClick={() => fireTrackEvent('Holdings', 'download_holdings')}
                        href={api.exportHoldingsUrl(selectedAccount?.id, i18n.language)}
                        rel="noreferrer"
                        target="_blank"
                        download={holdingsExportFilename}
                      >
                        {t('Exportera')}
                      </ButtonLinkExternal>
                      {unitSwitcher}
                    </FlexRow>
                  </SectionTitle>
                </FlexRow>
                <Spacer height={32} />
                <AccountHoldings showPercentage={showPercentage} />
              </GridItem>
            )}
            <GridItem xs={12} sm={6}>
              <AccountAllocation />
            </GridItem>
          </GridContainer>
        </GridItem>
      </>
    )
  } else {
    //Desktop
    content = (
      <>
        <GridItem xs={12}>
          <Box>
            {backButton}
            {boxWithHeaderAndSystemMessage}
          </Box>
        </GridItem>
        <GridItem xs={12}>
          <GridContainer alignItems="flex-start">
            <GridItem xs={12} sm={8}>
              <AccountPerformance
                accountId={selectedAccount?.id}
                accountNumber={selectedAccount?.number}
                performanceSerie={accountPerformance?.performanceSeries}
                selectedHoldingAccount={selectedHoldingAccount}
              />
            </GridItem>
            <GridItem xs={12} sm={4}>
              <AccountAllocation />
            </GridItem>
            <GridItem xs={12} sm={12}>
              <AccountSummarySegment
                accountId={selectedAccount?.id}
                performanceSerie={accountPerformance?.performanceSeries}
                currencyPositions={selectedHoldingAccount?.holdings?.currencies}
              />
              <MyOfferings mt={48} limit={4} minCardSize="1/3" accountIds={[selectedAccount?.id]} />
            </GridItem>
            {selectedHoldingAccount?.holdings && (
              <GridItem xs={12}>
                <FlexRow justifyContent="space-between" alignItems="center">
                  <SectionTitle title={t('Innehav')} sticky>
                    <FlexRow space={8}>
                      <ButtonLinkExternal
                        startIcon={<ButtonIcon icon={IconDownload} />}
                        size="small"
                        width="auto"
                        variant="tertiary"
                        onClick={() => fireTrackEvent('Holdings', 'download_holdings')}
                        href={api.exportHoldingsUrl(selectedAccount?.id, i18n.language)}
                        rel="noreferrer"
                        target="_blank"
                        download={holdingsExportFilename}
                      >
                        {t('Exportera')}
                      </ButtonLinkExternal>
                      {/* Hide toggle if user selects show more columns*/}
                      {!showMoreColumns && unitSwitcher}
                    </FlexRow>
                  </SectionTitle>
                </FlexRow>
                <Spacer height={16} />
                <AccountHoldings
                  showPercentage={showPercentage}
                  showMoreColumns={showMoreColumns}
                  setShowMoreColumns={setShowMoreColumns}
                  setSelectedUnit={setSelectedUnit}
                />
              </GridItem>
            )}
          </GridContainer>
        </GridItem>
      </>
    )
  }

  return <SelectedAccountIdProvider selectedAccountId={accountId}>{content}</SelectedAccountIdProvider>
})

AccountPage.displayName = 'AccountPage'

export default AccountPage

type AccountHoldingsProps = {
  showPercentage: boolean
  showMoreColumns?: boolean
  setShowMoreColumns?: (showMoreColumns: boolean) => void
  setSelectedUnit?: (selectedUnit) => void
}

const PORTFOLIO_MANAGEMENT = 'Portföljförvaltning'

const AccountHoldings = observer(function AccountHoldings({
  showPercentage,
  showMoreColumns,
  setShowMoreColumns,
  setSelectedUnit,
}: AccountHoldingsProps) {
  const { t } = useTranslation()
  const { holdingsGroups, groupBy, setGroupBy } = useHoldingsGroup()
  const { account, timeUpdated, updateData, isValidating } = useSelectedHoldingAccount(
    groupBy === holdingsGroups.PortfolioManagement
  )
  const breakpoint = useBreakpoint()
  const isDesktop = breakpoint >= Breakpoint.Large
  const serviceType = account?.information?.serviceType ?? ''

  const localStorage = useLocalStorage()

  const internalSetShowMoreColumns = (value) => {
    if (value && setSelectedUnit) {
      setSelectedUnit(DisplayUnit.Percentage)
    }
    setShowMoreColumns && setShowMoreColumns(value)
    localStorage.setItem('SHOW_MORE_COLUMNS', value, 'user')
  }

  useEffect(() => {
    const savedShowMoreColumns = localStorage.getItem('SHOW_MORE_COLUMNS', 'user')
    //localStorage returns strings
    setShowMoreColumns && setShowMoreColumns(savedShowMoreColumns === 'true')
  }, [localStorage, setShowMoreColumns])

  return (
    <Box>
      <FlexRow space={8} justifyContent="flex-start" alignItems="center">
        <Select
          mr={16}
          error={false}
          value={groupBy}
          width={176}
          label={t('Gruppera efter')}
          onChange={(_, value) => setGroupBy(value)}
          size="medium"
        >
          <Option key={holdingsGroups.InstrumentGroupName} value={holdingsGroups.InstrumentGroupName}>
            {t('Värdepappersgrupp')}
          </Option>
          <Option key={holdingsGroups.Allocation} value={holdingsGroups.Allocation}>
            {t('Tillgångsslag')}
          </Option>
          {serviceType === PORTFOLIO_MANAGEMENT && (
            <Option key={holdingsGroups.PortfolioManagement} value={holdingsGroups.PortfolioManagement}>
              {t('Portföljförvaltning')}
            </Option>
          )}
        </Select>
        <Tooltip
          title={t('Senast uppdaterad: {{time}}', {
            time: timeUpdated ? formatDate(timeUpdated, 'HH:mm') : '',
          })}
        >
          <Button
            mr={16}
            startIcon={!isValidating ? <Icon icon={IconRedo} size={16} /> : <LoadingIcon size={16} />}
            width="auto"
            variant="tertiary"
            size="medium"
            onClick={() => updateData()}
          >
            {t('Uppdatera')}
          </Button>
        </Tooltip>
        {/* Only show toggle for LG */}
        {isDesktop && (
          <FlexRow justifyContent="flex-start" alignItems="center">
            <Checkbox
              checked={showMoreColumns}
              onChange={() => {
                internalSetShowMoreColumns(!showMoreColumns)
                const eventType = showMoreColumns ? 'holdings_more_off' : 'holdings_more_on'
                fireTrackEvent('Account', eventType)
              }}
              label={t('Visa fler kolumner')}
              disabled={false}
            />
            <Tooltip title={t('Valet sparas på din enhet.')} placement="bottom">
              <div style={{ display: 'flex', minWidth: 0, marginLeft: 8 }}>
                <Icon icon={IconInfoOutlined} size={16} />
              </div>
            </Tooltip>
          </FlexRow>
        )}
      </FlexRow>
      <Spacer height={16} />
      {groupBy === holdingsGroups.PortfolioManagement && (
        <PortfolioManagementView showPercentage={showPercentage} showMoreColumns={showMoreColumns} />
      )}
      {groupBy === holdingsGroups.InstrumentGroupName && (
        <GroupName showPercentage={showPercentage} showMoreColumns={showMoreColumns} />
      )}
      {groupBy === holdingsGroups.Allocation && (
        <Allocations showPercentage={showPercentage} showMoreColumns={showMoreColumns} />
      )}
      {account?.holdings?.currencies && account?.holdings?.currencies?.length > 0 && <CurrencyPositions />}
    </Box>
  )
})

AccountHoldings.displayName = 'AccountHoldings'
