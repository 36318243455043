import { InstrumentPosition } from '@common/api/response'
import { useSelectedHoldingAccount } from '@common/hooks/useHoldings'

import { useTranslation } from 'react-i18next'

import { Box, Spacer } from '@carnegie/duplo'

import { HoldingsSegment } from './HoldingsSegment'

type Props = {
  showPercentage: boolean
  showMoreColumns: boolean
}

type BucketGroup = {
  bucket: string
  bucketTargetWeight: number
  bucketActualWeight: number
  modelPortfolios: {
    modelPortfolio: string
    instruments: InstrumentPosition[]
  }[]
}

export const PortfolioManagementView = ({ showPercentage, showMoreColumns }: Props) => {
  const { t } = useTranslation()
  const { account } = useSelectedHoldingAccount(true)
  const portfolioInstruments = account?.holdings?.instruments

  // Group instruments by bucket and modelPortfolio
  const groupedInstruments = portfolioInstruments?.reduce(
    (buckets: Record<string, Record<string, InstrumentPosition[]>>, instrument) => {
      const bucket = instrument.bucket || t('Okänd')
      const modelPortfolio = instrument.modelPortfolio || t('Okänd')

      if (!buckets[bucket]) {
        buckets[bucket] = {}
      }
      if (!buckets[bucket][modelPortfolio]) {
        buckets[bucket][modelPortfolio] = []
      }
      buckets[bucket][modelPortfolio].push(instrument)
      return buckets
    },
    {}
  )

  // Convert to array structure and calculate weights
  const bucketGroups: BucketGroup[] = Object.entries(groupedInstruments || {}).map(([bucket, modelPortfolios]) => {
    const instruments = Object.values(modelPortfolios).flat()
    const bucketTargetWeight = instruments[0]?.bucketTargetWeight || 0
    const bucketActualWeight = Object.values(modelPortfolios).reduce(
      (sum, portfolioInsts) => sum + (portfolioInsts[0]?.modelPortfolioWeight || 0),
      0
    )

    return {
      bucket,
      bucketTargetWeight,
      bucketActualWeight,
      modelPortfolios: Object.entries(modelPortfolios).map(([modelPortfolio, instrumentsForPortfolio]) => ({
        modelPortfolio,
        instruments: instrumentsForPortfolio,
      })),
    }
  })

  return (
    <Box>
      {bucketGroups.map((bucketGroup) => (
        <Box key={bucketGroup.bucket}>
          <HoldingsSegment
            key={bucketGroup.bucket}
            title={t(bucketGroup.bucket)}
            instruments={bucketGroup.modelPortfolios.flatMap((group) => group.instruments)}
            showPercentage={showPercentage}
            showMoreColumns={showMoreColumns}
            isPortfolioManagement
            tableSortStorageKey={`PM_${bucketGroup.bucket}`.replace(/\s+/g, '_')}
            weightSummary={{
              targetWeight: bucketGroup.bucketTargetWeight,
              actualWeight: bucketGroup.bucketActualWeight,
            }}
          />
          <Spacer height={32} />
        </Box>
      ))}
    </Box>
  )
}

PortfolioManagementView.displayName = 'PortfolioManagementView'
