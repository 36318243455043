import { CurrencyPosition, PerformanceSerieResponse } from '@common/api/response'
import { SkeletonLoader } from '@common/components/SkeletonLoader'
import { useAccountSummary } from '@common/hooks/useAccountSummary'
import { useAccountSummaryData } from '@common/hooks/useAccountSummaryData'
import { useSelectedHoldingAccount } from '@common/hooks/useHoldings'

import { ReactNode, useMemo } from 'react'

import { FlexCol, FlexRow, IconButton, IconChevronDown, IconChevronUp, Segment, SkeletonRect } from '@carnegie/duplo'

import { observer } from 'mobx-react-lite'

import { AccruedCoupon } from './MatrixSideDrawers/AccruedCoupon'
import { AcquisitionValue } from './MatrixSideDrawers/AcquisitionValue'
import { CouponCash } from './MatrixSideDrawers/CouponCash'
import { CurrencyPositions } from './MatrixSideDrawers/CurrencyPositions'
import { DividendCash } from './MatrixSideDrawers/DividendCash'
import MarginRequirement from './MatrixSideDrawers/MarginRequirement'
import { TradingCapacity } from './MatrixSideDrawers/TradingCapacity'
import { UnrealisedResult } from './MatrixSideDrawers/UnrealisedResult'

type AccountSummaryProps = {
  accountId: string
  performanceSerie: PerformanceSerieResponse
  currencyPositions: CurrencyPosition[]
}

export type AccountSummaryItem = {
  uniqueKey?: string
  currencyCode: string
  translatedLabel: string
  value: number
}

const generateAccountSummarySegment = (itemsToDisplay: ReactNode[], itemsPerRow: number) => {
  const items = []
  for (let i = 0; i < itemsToDisplay.length; i += itemsPerRow) {
    const rowItems = itemsToDisplay.slice(i, i + itemsPerRow)
    const rowItemPadding = itemsPerRow - rowItems.length

    // Get keys from the actual items if they exist
    const rowKey = rowItems
      .map((item, index) => {
        const element = item as { key?: string | null }
        return element?.key || `item-${i + index}`
      })
      .join('-')

    items.push(
      <FlexRow key={`row-${rowKey}`} flex={1} spaceX={16}>
        {[
          ...rowItems.map((item, index) => (
            <FlexCol key={`col-${rowKey}-${index}`} flex={1}>
              {item}
            </FlexCol>
          )),
          ...Array(rowItemPadding)
            .fill(null)
            .map((_, padIndex) => <FlexCol key={`pad-${rowKey}-${padIndex}`} flex={1} />),
        ]}
      </FlexRow>
    )
  }

  return items
}

export const AccountSummarySegment = observer(function AccountSummarySegment({
  accountId,
  performanceSerie,
  currencyPositions,
}: AccountSummaryProps) {
  const { accountSummary } = useAccountSummary(accountId)
  const { expanded, onExpandClick, shouldShowCoupon, getAccountSummaryItem, isExtraSmallScreen } =
    useAccountSummaryData(accountSummary, performanceSerie)
  const { account } = useSelectedHoldingAccount()

  const hasMarginRequirement = !!accountSummary?.marginRequirements?.amount

  const itemsToDisplay = useMemo(
    () =>
      [
        getAccountSummaryItem('EK') && <div key="EK">{getAccountSummaryItem('EK')}</div>,
        <CurrencyPositions key="currency-positions" currencyPositions={currencyPositions} accountId={accountId} />,
        account?.isTradeable ? <TradingCapacity key="trading-capacity" summary={accountSummary} /> : null,
        getAccountSummaryItem('MV') && <div key="MV">{getAccountSummaryItem('MV')}</div>,
        <UnrealisedResult key="unrealised-result" performanceSerie={performanceSerie} />,
        getAccountSummaryItem('RYR') && <div key="RYR">{getAccountSummaryItem('RYR')}</div>,
        <AcquisitionValue key="acquisition-value" summary={accountSummary} />,
        getAccountSummaryItem('BV') && <div key="BV">{getAccountSummaryItem('BV')}</div>,
        getAccountSummaryItem('KL') && <div key="KL">{getAccountSummaryItem('KL')}</div>,
        shouldShowCoupon ? <CouponCash key="coupon-cash" summary={accountSummary} accountId={accountId} /> : null,
        shouldShowCoupon ? <AccruedCoupon key="accrued-coupon" summary={accountSummary} accountId={accountId} /> : null,
        hasMarginRequirement ? <MarginRequirement key="margin-requirement" summary={accountSummary} /> : null,
        accountId ? (
          <DividendCash key="dividend-cash" summary={accountSummary} accountId={accountId} />
        ) : (
          <SkeletonRect key="skeleton-rect" width={'full'} height={42} />
        ),
      ].filter(Boolean),
    [
      account?.isTradeable,
      accountId,
      accountSummary,
      currencyPositions,
      getAccountSummaryItem,
      hasMarginRequirement,
      performanceSerie,
      shouldShowCoupon,
    ]
  )

  const skeletonLoaderHeight = isExtraSmallScreen ? 270 : 158
  const itemsPerRow = isExtraSmallScreen ? 2 : 4

  return (
    <Segment title="" variant="contained" height={'auto'} overflow="hidden">
      <SkeletonLoader height={expanded ? skeletonLoaderHeight : 42} loading={!accountSummary}>
        {() => {
          return (
            <FlexRow>
              <FlexCol flex={1} mr={16} spaceY={16}>
                {generateAccountSummarySegment(
                  expanded ? itemsToDisplay : itemsToDisplay.slice(0, itemsPerRow),
                  itemsPerRow
                )}
              </FlexCol>
              <FlexCol ml="auto" width={40} alignItems="flex-end">
                <IconButton
                  icon={expanded ? IconChevronUp : IconChevronDown}
                  onClick={onExpandClick}
                  size="large"
                  variant="uncontained"
                />
              </FlexCol>
            </FlexRow>
          )
        }}
      </SkeletonLoader>
    </Segment>
  )
})

AccountSummarySegment.displayName = 'AccountSummarySegment'
