import {
  CarnegieInstrumentGroup,
  CurrencyAmount,
  InfrontInstrument,
  InstrumentPosition,
  ReservedQuantity,
} from '@common/api/response'
import { InfrontFeedMetadataStore } from '@common/hooks/infront/sdk/useInfrontFeedMetadata'
import { useInfrontSDK } from '@common/hooks/infront/sdk/useInfrontSdk'
import { InstrumentIdContainer } from '@common/instrumentIdContainer'

import { useEffect, useReducer } from 'react'

import { makeObservable, observable } from 'mobx'
import { useLocalObservable } from 'mobx-react-lite'

const isInfrontInstrument = (instrument: InfrontInstrument): instrument is Infront.Instrument => true

const isCarnegieInstrumentGroup = (
  carnegieInstrumentGroup: string
): carnegieInstrumentGroup is CarnegieInstrumentGroup =>
  Object.values(CarnegieInstrumentGroup).includes(carnegieInstrumentGroup as CarnegieInstrumentGroup)

export class ObservableTableInstrument implements InstrumentIdContainer {
  isoCountryCode: string
  // Not used since infront is currently disabled for the table - @observable change: number
  // Not used since infront is currently disabled for the table - @observable changePercent: number
  // Not used since infront is currently disabled for the table - @observable preLastTradeDate: Date
  // Not used since infront is currently disabled for the table - @observable tradeTime: Date
  acquisitionCost: CurrencyAmount
  acquisitionCostOriginal: CurrencyAmount
  averageAcquisitionPrice: CurrencyAmount
  averageAcquisitionPriceOriginal: CurrencyAmount
  carnegieInstrumentGroup: CarnegieInstrumentGroup
  currencyCode: string
  feed: number
  instrumentIdCarnegie: string
  infrontInstrument: Infront.Instrument
  instrumentPriceDateTime: Date
  isin: string
  hasDetails: boolean
  key: string
  lastPrice: number
  marketIso: string
  marketValue: number
  marketValueOriginal: number
  name: string
  priceChangeToday: number
  priceChangeTodayOriginal: number
  priceChangeTodayRatio: number
  quantity: number
  ticker: string
  typeName: string
  unrealized: number
  unrealizedRatio: number
  priceChangeOneDay: number
  priceChangeOneDayOriginal: number
  priceChangeOneDayRatio: number
  latestEODPrice: CurrencyAmount
  latestEODDate: Date
  previousEODPrice: CurrencyAmount
  previousEODDate: Date
  region: string
  weight: number
  acquisitionFxRate: number
  allocation: string
  reservedQuantities?: ReservedQuantity[]
  marginRequirements?: CurrencyAmount | null
  modelPortfolio?: string

  constructor() {
    makeObservable(this, {
      isoCountryCode: observable,
    })
  }
}
class TableInstrumentsStore {
  private static idCounter = 0
  tableInstruments: ObservableTableInstrument[] = undefined
  private readonly storageKey: string

  constructor() {
    makeObservable(this, {
      tableInstruments: observable,
    })

    if (this.storageKey === '') {
      throw new Error(`Creating a TableInstrumentsStore without a storage key will make the storage global.`)
    }
  }

  init = (sdk: InfrontSDK.SDK, holdingsInstruments: InstrumentPosition[]) => {
    this.dispose()

    // We create one table instrument per holding instrument
    if (holdingsInstruments) {
      for (const holdingInstrument of holdingsInstruments) {
        this.addNewTableInstrument(holdingInstrument)
      }

      if (holdingsInstruments.length === 0) this.tableInstruments = []
    }

    const infrontInstrumentIds = holdingsInstruments
      ? holdingsInstruments.map((i) => i.instrument.infrontInstrument)
      : []

    const feedIds = infrontInstrumentIds
      .map((infrontInstrumentId) => infrontInstrumentId?.feed)
      .filter((feedNr) => feedNr !== undefined && feedNr !== null)

    // We need feed metadata for stuff like iso country code, if the sdk could not load we still continue
    if (sdk) {
      this.feedMetadataStore.init(sdk, feedIds, () => this.syncWithFeedMetadata())
    }

    this.initialized = true
  }

  initialized = false

  // Infront data is currently not used
  //instrumentsStore = new InstrumentsStore()

  feedMetadataStore = new InfrontFeedMetadataStore()

  dispose = () => {
    this.tableInstruments = undefined

    this.feedMetadataStore.dispose()
  }

  private readonly addNewTableInstrument = (holdingsInstrument: InstrumentPosition) => {
    const tableInstrument = new ObservableTableInstrument()

    // Add and transform data from holdings
    // There are cases where the id can be empty still so we always add our own unique id also
    TableInstrumentsStore.idCounter += 1
    tableInstrument.key = holdingsInstrument?.instrument?.id ?? `,instrument-${TableInstrumentsStore.idCounter}`

    tableInstrument.name = holdingsInstrument?.instrument?.name
    tableInstrument.ticker = holdingsInstrument?.instrument?.infrontInstrument?.ticker
    tableInstrument.feed = holdingsInstrument?.instrument?.infrontInstrument?.feed
    tableInstrument.isin = holdingsInstrument?.instrument?.isin
    tableInstrument.hasDetails = holdingsInstrument?.instrument?.hasDetails

    tableInstrument.acquisitionCost = {
      amount: holdingsInstrument?.acquisitionCost?.amount,
      currencyCode: holdingsInstrument?.acquisitionCost?.currencyCode,
    }

    tableInstrument.acquisitionCostOriginal = {
      amount: holdingsInstrument?.acquisitionCostOriginal?.amount,
      currencyCode: holdingsInstrument?.acquisitionCostOriginal?.currencyCode,
    }

    tableInstrument.averageAcquisitionPrice = {
      amount: holdingsInstrument?.averageAcquisitionPrice?.amount,
      currencyCode: holdingsInstrument?.averageAcquisitionPrice?.currencyCode,
    }

    tableInstrument.averageAcquisitionPriceOriginal = {
      amount: holdingsInstrument?.averageAcquisitionPriceOriginal?.amount,
      currencyCode: holdingsInstrument?.averageAcquisitionPriceOriginal?.currencyCode,
    }

    tableInstrument.carnegieInstrumentGroup =
      isCarnegieInstrumentGroup(holdingsInstrument?.instrument?.carnegieInstrumentGroup) &&
      holdingsInstrument?.instrument?.carnegieInstrumentGroup

    tableInstrument.instrumentPriceDateTime = new Date(holdingsInstrument.instrumentPriceDateTime)
    tableInstrument.infrontInstrument =
      isInfrontInstrument(holdingsInstrument.instrument.infrontInstrument) &&
      holdingsInstrument?.instrument?.infrontInstrument
    tableInstrument.instrumentIdCarnegie = holdingsInstrument?.instrument?.id

    tableInstrument.marketIso = holdingsInstrument?.instrument?.marketIso
    tableInstrument.marketValue = holdingsInstrument?.marketValue
    tableInstrument.marketValueOriginal = holdingsInstrument?.marketValueOriginal
    tableInstrument.name = holdingsInstrument?.instrument?.name
    tableInstrument.lastPrice = holdingsInstrument?.lastPrice
    tableInstrument.priceChangeToday = holdingsInstrument?.priceChangeToday
    tableInstrument.priceChangeTodayOriginal = holdingsInstrument?.priceChangeTodayOriginal
    tableInstrument.currencyCode = holdingsInstrument?.currencyCode

    tableInstrument.priceChangeTodayRatio = holdingsInstrument?.priceChangeTodayRatio
    tableInstrument.quantity = holdingsInstrument?.quantity
    tableInstrument.typeName = holdingsInstrument?.instrument?.typeName
    tableInstrument.unrealized = holdingsInstrument?.unrealized
    tableInstrument.unrealizedRatio = holdingsInstrument?.unrealizedRatio

    tableInstrument.priceChangeOneDay = holdingsInstrument.priceChangeOneDay
    tableInstrument.priceChangeOneDayOriginal = holdingsInstrument.priceChangeOneDayOriginal
    tableInstrument.priceChangeOneDayRatio = holdingsInstrument.priceChangeOneDayRatio
    tableInstrument.latestEODPrice = holdingsInstrument.latestEODPrice
    tableInstrument.latestEODDate = new Date(holdingsInstrument?.latestEODDate)
    tableInstrument.previousEODPrice = holdingsInstrument?.previousEODPrice
    tableInstrument.previousEODDate = new Date(holdingsInstrument?.previousEODDate)

    tableInstrument.region = holdingsInstrument.region
    tableInstrument.weight = holdingsInstrument.weight
    tableInstrument.acquisitionFxRate = holdingsInstrument.acquisitionFxRate
    tableInstrument.allocation = holdingsInstrument.instrument.allocation

    tableInstrument.reservedQuantities = holdingsInstrument.reservedQuantities
    tableInstrument.marginRequirements = holdingsInstrument.marginRequirements

    if (!this.tableInstruments) this.tableInstruments = []

    this.tableInstruments.push(tableInstrument)
  }

  // Called when the feed metadata comes in
  private readonly syncWithFeedMetadata = () => {
    if (this.tableInstruments) {
      for (const tableInstrument of this.tableInstruments) {
        const matchingInfrontFeedMetadata = this.feedMetadataStore.feedMetadata.find((feedMetadata) => {
          return feedMetadata?.feed === tableInstrument.feed
        })

        // Set data that depends on infront feed metadata
        if (matchingInfrontFeedMetadata) {
          tableInstrument.isoCountryCode = matchingInfrontFeedMetadata.isoCountry
        }
      }
    }
  }
}

/**
 * ## ⚠️IMPORTANT⚠️
 * ### Requires wrapping component in MobX `observer(...)`
 */
export function useTableInstruments(holdingsInstruments: InstrumentPosition[]) {
  const tableInstrumentsStore = useLocalObservable(() => new TableInstrumentsStore())
  const { infrontSDK, error } = useInfrontSDK()
  // calling refetch will force a rerender of component using hook-data
  const [shouldRefetch, refetch] = useReducer((x: number) => x + 1, 0)

  // We just use this for our deps array so we can now when to re-initialize the observable instruments
  const instrumentIds = holdingsInstruments ? holdingsInstruments.map((hi) => hi.instrument.id) : []

  useEffect(() => {
    if (infrontSDK && !error) {
      tableInstrumentsStore.init(infrontSDK, holdingsInstruments)
    } else if (error) {
      // Infront had an error but we can still display most of the data
      tableInstrumentsStore.init(undefined, holdingsInstruments)
    }

    return () => tableInstrumentsStore.dispose()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infrontSDK, JSON.stringify(instrumentIds), error, shouldRefetch])

  return {
    instruments: tableInstrumentsStore.tableInstruments,
    error: error,
    refetch: refetch,
  }
}
