import { FormattedNumber } from '@common/components/FormattedNumber'

import { useTranslation } from 'react-i18next'

import { formatNumber } from '@carnegie/digital-channels-frontend'
import { FlexRow, Td, Text, Tr, useDuploTheme } from '@carnegie/duplo'

import { observer } from 'mobx-react-lite'

import { TableAcquisitionColumnId } from './TableAcquisition'

type TableAcquisitionMobileFooterProps = {
  totalAcquisitionCost: number
}

type TableAcquisitionFooterProps = {
  totalAcquisitionCost: number
  columns: { id?: TableAcquisitionColumnId }[]
}

export const TableAcquisitionMobileFooter = observer(function TableAcquisitionMobileFooter({
  totalAcquisitionCost,
}: TableAcquisitionMobileFooterProps) {
  const theme = useDuploTheme()
  const { t } = useTranslation()

  return (
    <FlexRow
      p={16}
      css={{ borderTop: `1px solid ${theme.colors['bunker-60']}` }}
      justifyContent="space-between"
      alignItems="center"
    >
      <Text variant="support2">{t('Totalt anskaffningsvärde')}</Text>
      <FlexRow justifyContent="space-between" alignItems="baseline">
        <Text variant="subtitle1">{formatNumber(totalAcquisitionCost, { decimals: 0 })}</Text>
        <Text variant="label1" ml={2} color="bunker-main">
          {'SEK'}
        </Text>
      </FlexRow>
    </FlexRow>
  )
})

TableAcquisitionMobileFooter.displayName = 'TableAcquisitionMobileFooter'

export const TableAcquisitionFooter = observer(function TableAcquisitionFooter({
  totalAcquisitionCost,
  columns,
}: TableAcquisitionFooterProps) {
  const theme = useDuploTheme()
  const { t } = useTranslation()
  const hasExpanderColumn = columns.some((columnDef) => columnDef.id === 'expander-button')

  return (
    <tfoot css={{ borderTop: `1px solid ${theme.colors['bunker-60']}` }}>
      <Tr>
        {hasExpanderColumn && <Td />}
        <Td
          textAlign="left"
          css={{
            fontWeight: '500',
          }}
        >
          {t('Total')}
        </Td>
        <Td textAlign="right"></Td>
        <Td textAlign="right"></Td>
        <Td textAlign="right"></Td>
        <Td textAlign="right"></Td>
        <Td textAlign="right">
          <FormattedNumber decimals={0} justifyContent="flex-end" unit="SEK" bold value={totalAcquisitionCost} />
        </Td>
        <Td textAlign="right"></Td>
        <Td textAlign="right"></Td>
      </Tr>
    </tfoot>
  )
})

TableAcquisitionFooter.displayName = 'TableAcquisitionFooter'
