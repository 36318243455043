import { BuySellMenuItem, PlaceFundOrderMenuItem, TransactionMenuItem } from '@common/components/ActionButton'
import { FormattedNumber } from '@common/components/FormattedNumber'
import { MoreMenuWatchlist } from '@common/components/MoreMenu'

import { useTranslation } from 'react-i18next'

import { formatNumber } from '@carnegie/digital-channels-frontend'
import { FlexCol, MobileTableRow, Text } from '@carnegie/duplo'

import { useOpenInstrumentDrawer } from '../../../../drawers/instrumentDrawer/useOpenInstrumentDrawer'
import { InstrumentNameMobile } from '../InstrumentName'
import { ObservableTableInstrument } from '../useTableInstruments'

type Props = {
  showPercentage: boolean
  transactionOnClick?: () => void
  instrument: ObservableTableInstrument
  isFund: boolean
}

export const TableAcquisitionMobile = function TableAcquisitionMobile({
  instrument,
  transactionOnClick,
  isFund,
}: Props) {
  const { t } = useTranslation()
  const { openInstrumentDrawer } = useOpenInstrumentDrawer()

  const {
    acquisitionCost,
    averageAcquisitionPrice,
    carnegieInstrumentGroup,
    isoCountryCode,
    marketIso,
    name,
    quantity,
    reservedQuantities,
  } = instrument

  const showFlag = carnegieInstrumentGroup !== 'MutualFunds'

  return (
    <MobileTableRow
      divider
      action={
        <MoreMenuWatchlist instrumentIdContainer={instrument} size="medium">
          {isFund ? (
            <>
              <PlaceFundOrderMenuItem instrumentIdContainer={instrument} fundOrderType={'buy'} />
              <PlaceFundOrderMenuItem instrumentIdContainer={instrument} fundOrderType={'sell'} />
            </>
          ) : (
            <BuySellMenuItem instrumentIdContainer={instrument} />
          )}
          <TransactionMenuItem onClick={transactionOnClick} />
        </MoreMenuWatchlist>
      }
      title={
        <InstrumentNameMobile
          isoCountryCode={isoCountryCode ? isoCountryCode : marketIso}
          name={name}
          reservedQuantities={reservedQuantities}
          showFlag={showFlag}
        />
      }
      onClick={() => openInstrumentDrawer(instrument)}
    >
      <FlexCol alignItems="flex-start" flex={1}>
        <Text variant="label1" truncate>
          {t('Antal')}
        </Text>
        <Text variant="subtitle2" color="bunker-main">
          {formatNumber(quantity, { decimals: { min: 0, max: 10 } })}
        </Text>
      </FlexCol>
      <FlexCol alignItems="flex-start" flex={1}>
        <Text variant="label1" truncate>
          {t('Ansk. kurs')}
        </Text>
        <FormattedNumber
          variant="subtitle2"
          decimals={0}
          value={averageAcquisitionPrice.amount}
          unit={averageAcquisitionPrice.currencyCode}
        />
      </FlexCol>
      <FlexCol alignItems="flex-end" flex={1}>
        <Text variant="label1" truncate>
          {t('Ansk. värde')}
        </Text>
        <FormattedNumber
          variant="subtitle2"
          decimals={0}
          value={acquisitionCost.amount}
          unit={acquisitionCost.currencyCode}
        />
      </FlexCol>
    </MobileTableRow>
  )
}

TableAcquisitionMobile.displayName = 'TableAcquisitionMobile'
