import { InstrumentPosition } from '@common/api/response'
import { SkeletonLoader } from '@common/components/SkeletonLoader'
import { SmartTable } from '@common/components/SmartTable'
import { useSelectedHoldingAccount } from '@common/hooks/useHoldings'
import { sum } from '@common/utils/sum'

import { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Box, Breakpoint, useBreakpoint } from '@carnegie/duplo'

import { observer } from 'mobx-react-lite'

import { ObservableTableInstrument, useTableInstruments } from '../useTableInstruments'
import { TableOverviewColumnId } from './TableOverview'
import { TableOverviewFooter, TableOverviewMobileFooter } from './TableOverviewFooter'
import { TableOverviewPortfolioRow } from './TableOverviewPortfolioRow'

type TableOverviewPortfolioProps = {
  holdingsInstruments: InstrumentPosition[]
  showPercentage: boolean
  isFund?: boolean
  showMoreColumns: boolean
  tableSortStorageKey: string
  portfolioGroup?: string
  modelPortfolio?: string
}

type TableRowData = ObservableTableInstrument & {
  isGroup?: boolean
}

type DefaultInstrumentKeys = keyof Pick<
  ObservableTableInstrument,
  | 'instrumentIdCarnegie'
  | 'key'
  | 'name'
  | 'marketValue'
  | 'quantity'
  | 'unrealized'
  | 'unrealizedRatio'
  | 'priceChangeToday'
  | 'priceChangeTodayRatio'
  | 'priceChangeOneDay'
  | 'priceChangeOneDayRatio'
  | 'lastPrice'
>

const defaultInstrument: Pick<ObservableTableInstrument, DefaultInstrumentKeys> = {
  instrumentIdCarnegie: '',
  key: '',
  name: '',
  marketValue: 0,
  quantity: 0,
  unrealized: 0,
  unrealizedRatio: 0,
  priceChangeToday: 0,
  priceChangeTodayRatio: 0,
  priceChangeOneDay: 0,
  priceChangeOneDayRatio: 0,
  lastPrice: 0,
}

/**
 * Creates a portfolio group row for the table with default instrument values
 * @param portfolio - The name/identifier of the portfolio group
 * @returns A TableRowData object representing a portfolio group row
 */
const createPortfolioGroup = (portfolio: string): TableRowData =>
  ({
    ...defaultInstrument,
    isGroup: true,
    modelPortfolio: portfolio,
    instrumentIdCarnegie: `group-${portfolio}`,
    key: `group-${portfolio}`,
    name: portfolio,
  } as unknown as TableRowData)

export const TableOverviewPortfolio = observer(function TableOverviewPortfolio({
  holdingsInstruments,
  showPercentage,
  isFund = false,
  showMoreColumns,
  tableSortStorageKey,
}: TableOverviewPortfolioProps) {
  const { t } = useTranslation()
  const breakpoint = useBreakpoint()
  const isExtraSmallScreen = breakpoint <= Breakpoint.Xs
  const { account: selectedAccount } = useSelectedHoldingAccount()
  const navigate = useNavigate()

  const { instruments, error, refetch } = useTableInstruments(holdingsInstruments)

  const addSuffix = useCallback(
    (text: string, suffix = '%') => {
      return showMoreColumns ? `${text} ${suffix}` : text
    },
    [showMoreColumns]
  )

  // Memoize enriched instruments with early return for empty data
  const enrichedInstruments = useMemo(() => {
    if (!instruments?.length) return []

    return instruments.map((instrument) => {
      const holding = holdingsInstruments.find((h) => h.instrument.id === instrument.instrumentIdCarnegie)
      return {
        ...instrument,
        bucket: holding?.bucket,
        modelPortfolio: holding?.modelPortfolio,
      }
    })
  }, [instruments, holdingsInstruments])

  // Memoize grouped instruments with early return for empty data
  const groupedInstruments = useMemo(() => {
    if (!enrichedInstruments.length) return {}

    const groupMap = new Map<string, ObservableTableInstrument[]>()
    const unknownKey = t('Okänd')

    for (const instrument of enrichedInstruments) {
      const portfolio = instrument.modelPortfolio || unknownKey
      const group = groupMap.get(portfolio) || []
      group.push(instrument)
      groupMap.set(portfolio, group)
    }

    return Object.fromEntries(groupMap)
  }, [enrichedInstruments, t])

  // Memoize table data with early return for empty groups
  const tableData = useMemo(() => {
    if (!Object.keys(groupedInstruments).length) return []

    return Object.entries(groupedInstruments).flatMap(([portfolio, portfolioInstruments]) => [
      createPortfolioGroup(portfolio),
      ...portfolioInstruments.map(
        (inst) =>
          ({
            ...inst,
            isGroup: false,
            key: `instrument-${inst.key}`, // Ensure unique keys by adding prefix
          } as TableRowData)
      ),
    ])
  }, [groupedInstruments])

  // Memoize totals
  const totals = useMemo(() => {
    const safeInstruments = instruments || []
    return {
      totalMarketValues: sum('marketValue', safeInstruments),
      totalUnrealized: sum('unrealized', safeInstruments),
      totalAcquisitionCost: sum(
        'amount',
        safeInstruments.map((x) => x.acquisitionCost)
      ),
      totalPerformanceToday: sum(isFund ? 'priceChangeOneDay' : 'priceChangeToday', safeInstruments),
    }
  }, [instruments, isFund])

  const navigateToTransactions = useCallback(
    (instrument: ObservableTableInstrument) => {
      navigate(
        `/overview/transactions/${selectedAccount?.id}?instruments=${JSON.stringify([instrument.key])}&from=2005-01-01`
      )
    },
    [navigate, selectedAccount?.id]
  )

  useEffect(() => {
    refetch()
  }, [holdingsInstruments, refetch])

  const columns = useMemo(() => {
    const cols: NonNullable<Parameters<typeof SmartTable<TableRowData, TableOverviewColumnId>>[0]['columns']> = [
      {
        id: 'expander-button',
        visible: true, // Always show expander for portfolio groups
        width: 'auto',
      },
      {
        id: 'name',
        width: showMoreColumns ? 'minmax(0,4fr)' : 'minmax(0,2fr)',
        renderHeader: () => t('Namn'),
        sortBy: ({ row }: { row: TableRowData }) => row.name,
      },
      {
        id: 'quantity',
        width: '1fr',
        align: 'right' as const,
        renderHeader: () => t('Antal'),
        sortBy: ({ row }: { row: TableRowData }) => row.quantity,
      },
      {
        visible: !!showMoreColumns,
        id: (isFund ? 'priceChangeOneDay' : 'priceChangeToday') as TableOverviewColumnId,
        width: '1fr',
        align: 'right' as const,
        renderHeader: () => (isFund ? t('1 dag') + ' +/-' : t('Idag') + ' +/-'),
        sortBy: ({ row }: { row: TableRowData }) => (isFund ? row.priceChangeOneDay : row.priceChangeToday),
      },
      {
        id: 'today',
        width: '1fr',
        align: 'right' as const,
        renderHeader: () => {
          if (isExtraSmallScreen) {
            return isFund ? t('1 dag') : t('Idag')
          }
          return isFund ? addSuffix(t('1 dag')) : addSuffix(t('Idag'))
        },
        sortBy: ({ row }: { row: TableRowData }) => {
          if (showPercentage) {
            return isFund ? row.priceChangeOneDayRatio : row.priceChangeTodayRatio
          }
          return isFund ? row.priceChangeOneDay : row.priceChangeToday
        },
      },
      {
        id: 'lastPrice',
        width: '1fr',
        align: 'right' as const,
        renderHeader: () => t('Senast'),
        sortBy: ({ row }: { row: TableRowData }) => row.lastPrice,
      },
      {
        id: 'marketValue',
        width: '1fr',
        align: 'right' as const,
        renderHeader: () => t('Marknadsvärde'),
        sortBy: ({ row }: { row: TableRowData }) => row.marketValue,
      },
      {
        visible: !!showMoreColumns,
        id: 'unrealized',
        width: '1fr',
        align: 'right' as const,
        renderHeader: () => addSuffix(t('Orealiserat'), '+/-'),
        sortBy: ({ row }: { row: TableRowData }) => row.unrealized,
      },
      {
        id: 'unrealizedOrUnrealizedRatio',
        width: '1fr',
        align: 'right' as const,
        renderHeader: () => addSuffix(t('Orealiserat')),
        sortBy: ({ row }: { row: TableRowData }) => (showPercentage ? row.unrealizedRatio ?? 0 : row.unrealized ?? 0),
      },
      {
        visible: !!showMoreColumns,
        id: 'averageAcquisitionPrice',
        width: '1fr',
        align: 'right' as const,
        renderHeader: () => t('Ansk. kurs'),
        sortBy: ({ row }: { row: TableRowData }) => row.averageAcquisitionPrice?.amount,
      },
      {
        visible: !!showMoreColumns,
        id: 'acquisitionCost',
        width: '1fr',
        align: 'right' as const,
        renderHeader: () => t('Ansk. värde'),
        sortBy: ({ row }: { row: TableRowData }) => row.acquisitionCost?.amount,
      },
      {
        id: 'button',
        width: '48px',
      },
    ]
    return cols
  }, [t, showMoreColumns, isFund, isExtraSmallScreen, addSuffix, showPercentage])

  return (
    <Box>
      <SkeletonLoader
        error={error && !instruments}
        height={300}
        p={16}
        loading={!instruments}
        noDataLoaded={instruments?.length === 0}
      >
        {() => (
          <SmartTable<TableRowData, TableOverviewColumnId>
            renderMode={isExtraSmallScreen ? 'custom' : 'table'}
            sessionStorageKey={tableSortStorageKey}
            defaultSortBy="name"
            data={tableData}
            columns={columns}
            rowKey={(row) => row.key}
            renderRow={({ row, columns: rowColumns }) => {
              if (!row.isGroup) {
                return null
              }

              return (
                <TableOverviewPortfolioRow
                  key={row.key}
                  columns={rowColumns}
                  instruments={groupedInstruments[row.modelPortfolio]}
                  accountCurrencyCode={selectedAccount?.currencyCode}
                  showPercentage={showPercentage}
                  transactionOnClick={() => {
                    const instrument = groupedInstruments[row.modelPortfolio][0]
                    if (instrument) {
                      navigateToTransactions(instrument)
                    }
                  }}
                  isFund={isFund}
                  showMoreColumns={showMoreColumns}
                  modelPortfolio={row.modelPortfolio}
                />
              )
            }}
            renderFooterRow={({ columns: footerColumns }) => {
              return isExtraSmallScreen ? (
                <TableOverviewMobileFooter
                  totalPerformanceToday={totals.totalPerformanceToday}
                  totalMarketValues={totals.totalMarketValues}
                  totalUnrealized={totals.totalUnrealized}
                  showPercentage={showPercentage}
                />
              ) : (
                <TableOverviewFooter
                  columns={footerColumns}
                  totalPerformanceToday={totals.totalPerformanceToday}
                  totalMarketValues={totals.totalMarketValues}
                  totalUnrealized={totals.totalUnrealized}
                  totalAcquisitionCost={totals.totalAcquisitionCost}
                  showPercentage={showPercentage}
                  showMoreColumns={showMoreColumns}
                />
              )
            }}
          />
        )}
      </SkeletonLoader>
    </Box>
  )
})

TableOverviewPortfolio.displayName = 'TableOverviewPortfolio'
