import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Button, FlexRow, Paragraph, Popper, Spacer } from '@carnegie/duplo'

type DuplicateTransferConfirmProps = {
  onClick: () => void
  size: 'small' | 'medium' | 'large'
  loading?: boolean
  disabled?: boolean
  buttonText?: ReactNode
  confirmButtonText?: ReactNode
}

export const DuplicateTransferConfirm = ({
  onClick,
  disabled,
  buttonText,
  confirmButtonText,
}: DuplicateTransferConfirmProps) => {
  const { t } = useTranslation()

  return (
    <Popper
      id="confirm-submit-transfer"
      toggle={
        <Button width={224} variant="primary" size="medium" disabled={disabled}>
          {buttonText}
        </Button>
      }
    >
      {({ close }) => (
        <Box p={16}>
          <Paragraph variant="body1">{t('Är du säker på att du vill göra en liknande överföring igen?')}</Paragraph>
          <Spacer height={16} />
          <FlexRow alignItems="center">
            <Button
              variant="primary"
              size="medium"
              mr={16}
              onClick={() => {
                close()
                onClick()
              }}
            >
              {confirmButtonText ?? t('Bekräfta')}
            </Button>
            <Button variant="secondary" size="medium" onClick={close}>
              {t('Avbryt')}
            </Button>
          </FlexRow>
        </Box>
      )}
    </Popper>
  )
}
