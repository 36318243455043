import { useApi } from '@common/stores/store'
import { fireTrackEvent } from '@common/utils/analyticsEvent'

import { useCallback, useState } from 'react'

import useSWR from 'swr'

import { WebSocketCallback, useWebSocket } from './useWebSocket'

type WithdrawableAccountsErrorCodes =
  | 'CustomerNotUsingBankId'
  | 'NotPersonAbove18YearsOld'
  | 'NotEmployeeOrStaffRelated'
  | 'NoDefaultAccountsFound'
  | 'MaximumInternalAmountHasExceeded'
  | 'MaximumExternalAmountHasExceeded'
  | 'MaximumExternalAmountHasExceededFor7days'
  | 'LivingOutsideOfEuEes'

enum TransferWebsocketMessage {
  CREATED = 'TRANSFER_ORDER_PAYMENT_CREATED',
  STATE_UPDATE = 'TRANSFER_ORDER_PAYMENT_STATE_UPDATE',
  CANCELLED = 'TRANSFER_ORDER_PAYMENT_CANCELLED',
  BOOKET_IN_ABASEC = 'TRANSFER_ORDER_PAYMENT_BOOKED_IN_ABASEC',
}

interface AvailableAccountOptions {
  includeDefaultAccounts: boolean
}

export const useAvailableAccounts = (options?: AvailableAccountOptions) => {
  const api = useApi()

  const { data, error, mutate } = useSWR(
    `/transfers/Available-accounts${options?.includeDefaultAccounts ? '?includeDefaultAccounts=true' : ''}`,
    () => api.getAvailableAccounts(options?.includeDefaultAccounts ?? false)
  )

  return {
    availableAccounts: data?.availableAccounts,
    availableDefaultAccounts: data?.availableDefaultAccounts,
    remainingPeriodLimit: data?.transferLimit?.amountLeftToPeriodLimit,
    maxInternalAmount: data?.transferLimit?.customerSingleTransferLimitForRelatedDepot,
    maxExternalAmount: data?.transferLimit?.customerSingleTransferLimitForDefaultAccount,
    maxSevenDayAmount: data?.transferLimit?.customerPeriodTransferLimitForDefaultAccount,
    canReceivePendingAmount: data?.canReceivePendingAmount,
    canReceivePendingOrderAmount: data?.canReceivePendingOrderAmount,
    validationErrorCodes: data?.validationErrorCodes as WithdrawableAccountsErrorCodes[],
    updateAccounts: mutate,
    networkError: error,
  }
}

export const useOngoingTransfers = () => {
  const api = useApi()
  const { data, error, mutate, isValidating } = useSWR('/transfers', () => api.getOngoingTransfers())

  const transferCheck = useCallback<WebSocketCallback>(
    ({ type }) => {
      if (Object.values(TransferWebsocketMessage).includes(type as TransferWebsocketMessage)) {
        mutate()
      }
    },
    [mutate]
  )
  useWebSocket(transferCheck)

  return {
    ongoingTransfers: data,
    updateOngoingTransfers: mutate,
    networkError: error,
    isValidating,
  }
}

export const useCancelTransfer = () => {
  const api = useApi()
  const [cancelTransferError, setCancelTransferError] = useState(false)
  const { updateOngoingTransfers } = useOngoingTransfers()
  const cancelTransfer = async (transferOrderId: number) => {
    try {
      await api.cancelTransfer(transferOrderId)
      fireTrackEvent('Transfers', 'delete_transfer_successful', { transferOrderId })
      updateOngoingTransfers()
    } catch (e) {
      console.error(e)
      fireTrackEvent('Transfers', 'delete_transfer_error', { transferOrderId, error: e })
      setCancelTransferError(true)
      updateOngoingTransfers()
    }
  }

  return { cancelTransfer, cancelTransferError, clearTransferError: () => setCancelTransferError(false) }
}
