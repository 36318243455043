import { AvailableAccount, DefaultAccount, TransferRequest, TransferResponse } from '@common/api/response'
import { AccountSelectListItem } from '@common/components/AccountSelect'
import { BankIdSignModal } from '@common/components/BankIdSignModal'
import { DuplicateTransferConfirm } from '@common/components/DuplicateTransferConfirm'
import { useOngoingTransfers } from '@common/hooks/useTransfer'
import { WebSocketCallback, WebSocketMessage, useWebSocket } from '@common/hooks/useWebSocket'
import { useApi } from '@common/stores/store'
import { getOwnerDescription } from '@common/utils/accountOwnerDescription'
import { fireTrackEvent } from '@common/utils/analyticsEvent'
import { EmotionJSX } from '@emotion/react/types/jsx-namespace'

import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { formatNumber } from '@carnegie/digital-channels-frontend'
import {
  Banner,
  Box,
  Button,
  FlexCol,
  FlexRow,
  IconButton,
  IconInfoOutlined,
  Input,
  Link,
  ListItem,
  ListItemRow,
  LoadingIcon,
  Option,
  Paragraph,
  Popper,
  Progress,
  Select,
  SkeletonRect,
  SkeletonText,
} from '@carnegie/duplo'

import i18n from '@/i18n'

import { useTransferPage } from './TransferPageContext'

type AccountCollection = {
  validFrom: AvailableAccount[]
  invalidFrom: AvailableAccount[]
  validToAvailable: AvailableAccount[]
  invalidToAvailable: AvailableAccount[]
}
type ToDefaultAccountCollection = {
  validToDefault: DefaultAccount[]
  invalidToDefault: DefaultAccount[]
}

export enum TransferType {
  INTERNAL = 'internal',
  EXTERNAL = 'external',
}

type TransferProps = { defaultAccount?: string; transferType: TransferType }

const Transfer = ({ defaultAccount, transferType }: TransferProps) => {
  const { t } = useTranslation()
  const api = useApi()

  const { updateOngoingTransfers } = useOngoingTransfers()
  const { page, setPage, setRegisteringTransfer, registeringTransfer, accounts } = useTransferPage()
  const [fromAccount, setFromAccount] = useState<AvailableAccount>(null)
  const [toAccount, setToAccount] = useState<AvailableAccount | DefaultAccount>(null)
  const [amount, setAmount] = useState<string>(null)
  const [transferRegistered, setTransferRegistered] = useState(false)
  const [transferResponse, setTransferResponse] = useState<TransferResponse>(null)
  const [showBankIdSigningModal, setShowBankIdSigningModal] = useState(false)
  const [disabledForm, setDisabledForm] = useState(false)
  const submitButtonText = transferType === TransferType.INTERNAL ? t('Bekräfta överföring') : t('Signera med BankID')
  let tempAccountOwnerName: string = null

  const {
    availableAccounts,
    availableDefaultAccounts,
    remainingPeriodLimit,
    maxInternalAmount,
    maxExternalAmount,
    maxSevenDayAmount,
    canReceivePendingAmount,
    canReceivePendingOrderAmount,
    validationErrorCodes,
    updateAccounts,
    networkError,
  } = accounts

  const maxAmount = transferType === TransferType.INTERNAL ? maxInternalAmount : maxExternalAmount
  const toAccountSource =
    transferType === TransferType.INTERNAL ? availableAccounts : availableDefaultAccounts?.[fromAccount?.id ?? '']
  const inputErrorText = useMemo(() => {
    if (!amount) {
      return null
    }

    const amountNumber = Number(amount)

    const exceedingSingleTransactionLimit = amountNumber > maxAmount
    if (exceedingSingleTransactionLimit) {
      return t('Beloppet överskrider maxgränsen på {amount} SEK för en enskild överföring').replaceAll(
        '{amount}',
        formatNumber(maxAmount)
      )
    }

    const exceedingWithdrawableAmount = amountNumber > fromAccount?.withdrawableAmount?.amount
    if (exceedingWithdrawableAmount) {
      return t('Beloppet överskrider tillgängligt saldo')
    }

    const exceedingPeriodLimit = transferType === TransferType.EXTERNAL && amountNumber > remainingPeriodLimit
    if (exceedingPeriodLimit) {
      return t('Beloppet överskrider maxgränsen på {amount} SEK för överföringar över en 7-dagarsperiod').replaceAll(
        '{amount}',
        formatNumber(maxSevenDayAmount)
      )
    }
  }, [
    amount,
    fromAccount?.withdrawableAmount?.amount,
    maxAmount,
    maxSevenDayAmount,
    remainingPeriodLimit,
    t,
    transferType,
  ])

  const onBankIdModalClose = useCallback(() => {
    setShowBankIdSigningModal(false)
  }, [])

  const { validFrom, invalidFrom, validToAvailable, invalidToAvailable } = useMemo(
    () =>
      availableAccounts?.reduce<AccountCollection>(
        (collection, account) => {
          if (account.isDisabled) {
            collection.invalidFrom.push(account)
            collection.invalidToAvailable.push(account)
            return collection
          }

          if (account.validAsFromAccount) {
            collection.validFrom.push(account)
          } else {
            collection.invalidFrom.push(account)
          }

          if (account.validAsToAccount && account.id !== fromAccount?.id) {
            collection.validToAvailable.push(account)
          } else {
            collection.invalidToAvailable.push(account)
          }

          return collection
        },
        { validFrom: [], invalidFrom: [], validToAvailable: [], invalidToAvailable: [] }
      ) ?? { validFrom: [], invalidFrom: [], validToAvailable: [], invalidToAvailable: [] },
    [fromAccount, availableAccounts]
  )

  const { validToDefault, invalidToDefault } = useMemo(
    () =>
      availableDefaultAccounts?.[fromAccount?.id ?? '']?.reduce<ToDefaultAccountCollection>(
        (collection, account) => {
          if (account.isDisabled || !account.validAsToAccount) {
            collection.invalidToDefault.push(account)
            return collection
          } else if (account.validAsToAccount) {
            collection.validToDefault.push(account)
          }

          return collection
        },
        { validToDefault: [], invalidToDefault: [] }
      ) ?? { validToDefault: [], invalidToDefault: [] },
    [availableDefaultAccounts, fromAccount?.id]
  )

  const validTo = transferType === TransferType.INTERNAL ? validToAvailable : validToDefault
  const invalidTo = transferType === TransferType.INTERNAL ? invalidToAvailable : invalidToDefault

  useEffect(() => {
    if (transferRegistered) return

    const account = availableAccounts?.find((acc) => acc.id === defaultAccount)
    if (account && !account.isDisabled && account.validAsFromAccount) {
      setFromAccount(account)
    }
  }, [defaultAccount, availableAccounts, transferRegistered])

  const reFetchAccountData = useCallback(async () => {
    await updateAccounts()
    setDisabledForm(false)
  }, [updateAccounts])

  useEffect(() => {
    reFetchAccountData()
  }, [page, reFetchAccountData])

  const messageHandler = useCallback<WebSocketCallback>(
    async (message: WebSocketMessage) => {
      if (!message?.payload || !message?.type) return

      const isRelatedDepot = message.payload?.['transferTarget']?.['transferType'] === 1 // internal transfer

      if (
        (message.type === 'TRANSFER_ORDER_PAYMENT_CREATED' || message.type === 'TRANSFER_ORDER_PAYMENT_CANCELLED') &&
        !isRelatedDepot
      ) {
        reFetchAccountData()
      }
      if (message.type === 'TRANSFER_ORDER_PAYMENT_BOOKED_IN_ABASEC' && isRelatedDepot) {
        reFetchAccountData()
      }
    },
    [reFetchAccountData]
  )
  useWebSocket(messageHandler)

  useEffect(() => {
    setRegisteringTransfer(null)
  }, [setRegisteringTransfer, transferType])

  useEffect(() => {
    if (registeringTransfer === 'success') {
      setFromAccount(null)
      setToAccount(null)
      setAmount(null)
      setTransferRegistered(true)
      updateOngoingTransfers()
    }
  }, [registeringTransfer, setRegisteringTransfer, updateOngoingTransfers])

  const renderAccount = useCallback(
    (selectedValue: AvailableAccount) => {
      return selectedValue ? (
        <Paragraph variant="input2" color={'text-default'} truncate>
          {selectedValue?.number +
            ' (' +
            getOwnerDescription(selectedValue?.owners, selectedValue?.policyHolder, t) +
            ')'}
        </Paragraph>
      ) : undefined
    },
    [t]
  )

  const getBase64LatestTransfer = (from: string, amountValue: string, to: string | number) => {
    if (!(from && amountValue && to)) return null

    const obj = { from, amountValue, to }
    return btoa(JSON.stringify(obj))
  }

  const makeTransferRequest = async () => {
    setDisabledForm(true)
    setRegisteringTransfer('sent')
    const eventName = transferType === TransferType.INTERNAL ? 'confirm_internaltransfer' : 'confirm_withdrawal'
    fireTrackEvent('Transfers', `${eventName}_successful`)

    try {
      const toAccountProperty: keyof Pick<TransferRequest, 'toAccount' | 'toExternalAccount'> =
        transferType === TransferType.INTERNAL ? 'toAccount' : 'toExternalAccount'
      const body: TransferRequest = {
        fromAccount: fromAccount.id,
        amount: Number(amount),
        [toAccountProperty]: toAccount.id,
        languageCode: i18n.language,
      }

      sessionStorage.setItem('latestTransfer', getBase64LatestTransfer(fromAccount.id, amount, toAccount.id))
      setTransferResponse(await api.registerTransfer(body))

      if (transferType === TransferType.EXTERNAL) setShowBankIdSigningModal(true)
      else setRegisteringTransfer('success')

      setPage(1)
    } catch (e) {
      console.error(e)
      fireTrackEvent('Transfers', `${eventName}_error`, { error: e })
      setRegisteringTransfer('error')
    }
  }

  const renderDefaultAccount = ({ displayNumber, bankName }: DefaultAccount) => `${displayNumber} (${bankName})`

  const createAccountOption = useCallback(
    (account: AvailableAccount, hasDivider: boolean, isSelected: boolean, isDisabled: boolean = false) => {
      const withdrawableAmountText = `${t('Tillgängligt saldo')}: ${formatNumber(account?.withdrawableAmount?.amount, {
        decimals: 2,
      })} ${account?.withdrawableAmount?.currencyCode ?? ''}`

      return (
        <Option disabled={isDisabled} key={account.id} value={account} p={0}>
          <AccountSelectListItem
            account={account}
            isSelected={isSelected}
            divider={hasDivider}
            extraRow={
              <Paragraph variant="support1" truncate color="text-low-emphasis">
                {withdrawableAmountText}
              </Paragraph>
            }
          />
        </Option>
      )
    },
    [t]
  )

  const createWithdrawableAccountOption = useCallback(
    (account: DefaultAccount, hasDivider: boolean, isSelected: boolean, isDisabled: boolean = false) => {
      return (
        <Option disabled={isDisabled} key={account.id} value={account} p={0}>
          <ListItem width="full" divider={hasDivider}>
            <ListItemRow title={<Paragraph variant="subtitle1">{account?.displayNumber}</Paragraph>} />
            <ListItemRow
              title={
                <Paragraph variant="label1" truncate color="text-low-emphasis">
                  {account?.bankName}
                </Paragraph>
              }
            />
          </ListItem>
        </Option>
      )
    },
    []
  )

  if (networkError) {
    return (
      <Box p={16}>
        <Banner
          severity="critical"
          title={t('Problem med överföringar')}
          description={t(
            'Just nu går det inte registrera eller hämta information om överföringar. Vänligen försök igen senare.'
          )}
        />
      </Box>
    )
  }

  if (!availableAccounts) {
    return (
      <FlexCol p={16} spaceY={16}>
        <SkeletonText height={24} />
        <SkeletonRect width="full" height={40} />
        <SkeletonRect width="full" height={40} />
        <SkeletonRect width="50%" height={40} />
        <SkeletonRect width="50%" height={40} />
        <SkeletonText height={24} />
      </FlexCol>
    )
  }

  if (
    transferType === TransferType.EXTERNAL &&
    availableAccounts &&
    validationErrorCodes?.includes('NotEmployeeOrStaffRelated')
  ) {
    return (
      <FlexRow alignItems="center" p={16}>
        <Paragraph variant="body2">
          {t('Vi jobbar kontinuerligt med vår nya webb. Tills vidare,')}&nbsp;
          <Link variant="subtitle2" to="/profile/contact">
            {t('kontakta oss')}
          </Link>
          &nbsp;
          {t('för att få hjälp med att göra ett uttag.')}
        </Paragraph>
      </FlexRow>
    )
  }

  return (
    <>
      {showBankIdSigningModal && (
        <BankIdSignModal
          openModal={showBankIdSigningModal}
          transferResponse={transferResponse}
          onClosing={onBankIdModalClose}
          setParentDisabledForm={setDisabledForm}
          setRegisteringTransfer={setRegisteringTransfer}
        />
      )}
      <FlexCol p={16} spaceY={16}>
        {registeringTransfer === 'error' && (
          <Banner
            severity="critical"
            title={t('Överföringen kunde inte registreras')}
            description={t('Försök igen, eller kontakta oss om felet kvarstår.')}
            onClose={() => setRegisteringTransfer(null)}
          />
        )}
        {!disabledForm && registeringTransfer === 'success' && (
          <Banner
            severity="success"
            title={t('Överföring registrerad')}
            description={t('Överföringen är nu registrerad och under bearbetning.')}
            onClose={() => setRegisteringTransfer(null)}
          />
        )}
        {validationErrorCodes?.includes('LivingOutsideOfEuEes') ? (
          <Banner
            severity="information"
            title={t('Överföringar ej tillgängligt')}
            description={t(
              'Du har begränsad tillgång till våra tjänster eftersom du är bosatt utanför EU/EES. Tillsvidare, kontakta oss för att få hjälp med att göra ett uttag.'
            )}
          />
        ) : validationErrorCodes?.includes('CustomerNotUsingBankId') ? (
          <Banner
            severity="information"
            title={t('Funktionen kräver inloggning med BankId')}
            description={t(
              'För att göra överföringar måste du logga in med BankId. Om du har ett BankId och vill göra en överföring så logga ut och välj det som inloggningsalternativ.'
            )}
          />
        ) : validationErrorCodes?.includes('NotPersonAbove18YearsOld') ? (
          <Banner
            severity="information"
            title={t('Överföringar ej tillgängligt')}
            description={t(
              'För att göra överföringar måste du vara 18 år. Tills vidare får du kontakta oss så hjälper vi dig.'
            )}
          />
        ) : transferType === TransferType.EXTERNAL &&
          ((availableDefaultAccounts &&
            Object.values(availableDefaultAccounts).reduce(
              (accumulator, defaultAccounts) => accumulator + defaultAccounts.length,
              0
            ) === 0) ||
            validationErrorCodes?.includes('NoDefaultAccountsFound')) ? (
          <Banner
            severity="information"
            title={t('Uttag kräver ett förvalt konto')}
            description={
              <Paragraph variant="body1">
                {t('För att göra ett uttag krävs ett förvalt konto,')}&nbsp;
                <Link to={`/profile/contact`}>{t('kontakta oss')}</Link>&nbsp;
                {t('för att lägga till ett.')}
              </Paragraph>
            }
          />
        ) : null}
        {canReceivePendingAmount === false && (
          <Banner
            severity="warning"
            title=""
            description={t(
              'Just nu kan vi inte hämta information om pågående överföringar som kan påverka tillgängligt saldo.'
            )}
          />
        )}
        {canReceivePendingOrderAmount === false && (
          <Banner
            severity="warning"
            title=""
            description={t(
              'Just nu kan vi inte hämta information om pågående köpordrar som kan påverka tillgängligt saldo.'
            )}
          />
        )}
        <Paragraph variant="body1">
          {transferType === TransferType.INTERNAL
            ? t('Gör en överföring mellan dina konton hos Carnegie.')
            : t('Gör ett uttag till ett förvalt konto.')}
        </Paragraph>
        {disabledForm && registeringTransfer === 'success' ? (
          <Progress loading />
        ) : (
          <>
            <FlexCol spaceY={8}>
              <Select
                disabled={disabledForm || !availableAccounts?.length}
                value={fromAccount ?? ''}
                label={t('Från konto')}
                onChange={(_, e) => {
                  setFromAccount(e)
                  setToAccount(null)
                  setAmount(null)
                }}
                renderValue={renderAccount}
              >
                <Box pl={16} key="available">
                  <Paragraph variant="overline" truncate color="text-low-emphasis">
                    {t('Tillgängliga').toUpperCase()}
                  </Paragraph>
                </Box>
                {validFrom.length ? (
                  validFrom.map((account, index) => {
                    const isSelected = fromAccount?.id === account.id
                    const hasDivider = index < availableAccounts.length - 1
                    return createAccountOption(account, hasDivider, isSelected)
                  })
                ) : (
                  <Box p={16} key="test">
                    <Paragraph variant="label1">{t('Det finns inget tillgängligt konto att välja')}</Paragraph>
                  </Box>
                )}
                {!!invalidFrom.length && (
                  <Box pt={16} pl={16} key="not_available">
                    <Paragraph variant="overline" truncate color="text-low-emphasis">
                      {t('Ej tillgängliga').toUpperCase()}
                    </Paragraph>
                    {invalidFrom.map((account, index) => {
                      const isSelected = fromAccount?.id === account.id
                      const hasDivider = index < availableAccounts.length - 1
                      const isDisabled = true
                      return createAccountOption(account, hasDivider, isSelected, isDisabled)
                    })}
                  </Box>
                )}
              </Select>

              {fromAccount && (
                <FlexRow alignItems="baseline">
                  <Paragraph variant="input2">{`${t('Tillgängligt saldo')}: ${formatNumber(
                    fromAccount?.withdrawableAmount?.amount,
                    {
                      decimals: 2,
                    }
                  )}`}</Paragraph>
                  <Paragraph ml={4} variant="label1" color="text-default">
                    {fromAccount?.withdrawableAmount?.currencyCode ?? ''}
                  </Paragraph>
                </FlexRow>
              )}
            </FlexCol>
            <FlexRow>
              <Select
                disabled={disabledForm || !fromAccount}
                value={toAccount ?? ''}
                label={transferType === TransferType.INTERNAL ? t('Till konto') : t('Till förvalt konto')}
                onChange={(_, e) => setToAccount(e)}
                renderValue={transferType === TransferType.INTERNAL ? renderAccount : renderDefaultAccount}
              >
                {validTo.length ? (
                  validTo.map((account: AvailableAccount | DefaultAccount, index) => {
                    const isSelected = toAccount?.id === account.id
                    const hasDivider = index < toAccountSource.length - 1

                    let option: EmotionJSX.Element
                    if ('bankName' in account) {
                      option = createWithdrawableAccountOption(account, hasDivider, isSelected)
                    } else if ('validAsFromAccount' in account) {
                      option = createAccountOption(account, hasDivider, isSelected, disabledForm)
                    }

                    const defaultAccountGroup = [] // Needed as Mui-select can't handle fractions
                    const AccountOwnerName = (account as DefaultAccount)?.personName
                    if (tempAccountOwnerName !== AccountOwnerName) {
                      // Write a new owner-label if this is different than in last iteration
                      defaultAccountGroup.push(
                        <Box pl={16} pt={16} key="available">
                          <Paragraph variant="overline" truncate color="text-low-emphasis">
                            {transferType === TransferType.INTERNAL
                              ? t('Tillgängliga').toUpperCase()
                              : `${t('Förvalt konto').toUpperCase()} - ${AccountOwnerName}`}
                          </Paragraph>
                        </Box>
                      )
                    }
                    defaultAccountGroup.push(option)
                    tempAccountOwnerName = AccountOwnerName

                    return defaultAccountGroup
                  })
                ) : (
                  <Box p={16} key="test">
                    <Paragraph variant="label1">{t('Det finns inga tillgängliga konton att välja.')}</Paragraph>
                  </Box>
                )}
                {!!invalidTo.length && (
                  <Box pt={16} pl={16} key="not_available">
                    <Paragraph variant="overline" truncate color="text-low-emphasis">
                      {t('Ej tillgängliga').toUpperCase()}
                    </Paragraph>
                    {invalidTo.map((account, index) => {
                      const isSelected = toAccount?.id === account.id
                      const hasDivider = index < toAccountSource.length - 1
                      const isDisabled = true

                      if ('bankName' in account) {
                        return createWithdrawableAccountOption(account, hasDivider, isSelected, isDisabled)
                      } else if ('validAsFromAccount' in account) {
                        return createAccountOption(account, hasDivider, isSelected, isDisabled)
                      }
                      return null
                    })}
                  </Box>
                )}
              </Select>
              {transferType === TransferType.EXTERNAL && (
                <Box ml={4}>
                  <Popper
                    id="fund-order-info"
                    toggle={
                      <FlexRow>
                        <IconButton size="large" variant="uncontained" icon={IconInfoOutlined} />
                      </FlexRow>
                    }
                  >
                    <Box p={16} css={{ width: 500, maxWidth: '90vw' }}>
                      <Paragraph>
                        {t(
                          'Ett förvalt konto är ett konto som du har registrerat hos oss för uttag. Kontakta oss om du vill lägga till eller ta bort ett konto.'
                        )}
                      </Paragraph>
                    </Box>
                  </Popper>
                </Box>
              )}
            </FlexRow>
            <Input
              disabled={!availableAccounts?.length || !fromAccount || !toAccount}
              label={t('Belopp att överföra (SEK)')}
              autoComplete="off"
              value={amount ?? ''}
              error={!!inputErrorText}
              helperText={inputErrorText}
              onChange={(event) => {
                setAmount(event.target.value)
              }}
              inputMode="decimal"
              type="text"
              inputFormat={{
                type: 'numeric',
                allowedDecimals: 2,
                fixedDecimalScale: true,
                thousandSeparator: true,
              }}
            />
            {sessionStorage.getItem('latestTransfer') &&
            getBase64LatestTransfer(fromAccount?.id, amount, toAccount?.id) ===
              sessionStorage.getItem('latestTransfer') ? (
              <DuplicateTransferConfirm
                buttonText={submitButtonText}
                disabled={!fromAccount || !toAccount || !amount || !!inputErrorText || registeringTransfer === 'sent'}
                onClick={makeTransferRequest}
                size={'medium'}
              />
            ) : (
              <Button
                width={224}
                variant="primary"
                size="medium"
                disabled={!fromAccount || !toAccount || !amount || !!inputErrorText || registeringTransfer === 'sent'}
                startIcon={registeringTransfer === 'sent' ? <LoadingIcon size={24} /> : null}
                onClick={makeTransferRequest}
              >
                {submitButtonText}
              </Button>
            )}
          </>
        )}
      </FlexCol>
    </>
  )
}

export default Transfer
