import { CountryCodeFlag } from '@common/components/CountryCodeToFlag'
import { HtmlWithCustomComponents } from '@common/components/HtmlWithCustomComponentsProps'
import { SkeletonLoader } from '@common/components/SkeletonLoader'
import { Disclaimer } from '@common/components/drawer/Disclaimer'
import { useDrawerProps } from '@common/components/drawer/useDrawerStore'
import { createInstrumentIdContainer } from '@common/instrumentIdContainer'
import { useApi } from '@common/stores/store'
import { fireTrackEvent } from '@common/utils/analyticsEvent'

import { useTranslation } from 'react-i18next'

import { checkToday } from '@carnegie/digital-channels-frontend'
import {
  Box,
  Breakpoint,
  ButtonLinkExternal,
  DividerLine,
  FlexRow,
  GridContainer,
  GridItem,
  Heading5,
  Icon,
  IconExternalLink,
  LinkExternal,
  Segment,
  Spacer,
  Tag,
  TagLabel,
  Text,
  useBreakpoint,
} from '@carnegie/duplo'

import { CommissionedResearchIcon } from '@/pages/research/researchExplore/ResearchListItemIcon'

import { observer } from 'mobx-react-lite'
import useSWR from 'swr'

import { RelatedInstruments } from '../../pages/news/RelatedInstruments'
import { UpdateBasedOnResearch } from '../../pages/research/researchAndComments/UpdateBasedOnResearch'
import { ResearchNewsItem } from '../../pages/research/shared/researchNewsItem'

export function useResearchNewsItem(id: string, type: 'News' | 'Research'): ResearchNewsItem {
  const api = useApi()

  id = !isNaN(Number(id))
    ? `${type}_${id}` //legacy
    : id

  const { data } = useSWR(id ? `content/analysis/${id}` : undefined, () => api.fetchResearchNewsItem(id))
  return data?.item
}

const ResearchDrawer = observer(() => {
  const { routeProps: researchId } = useDrawerProps()
  const breakpoint = useBreakpoint()
  const isMobile = breakpoint < Breakpoint.Small

  const { t } = useTranslation()
  const item = useResearchNewsItem(researchId, 'Research')
  const isCommissionedResearch =
    item?.productType === 'Commissioned Research' ||
    item?.productType === 'CarnegieCommissioned' ||
    item?.productType === 'PenserCommissioned' ||
    item?.productType === 'CarnegieAccess' ||
    item?.productType === 'Carnegie Access'

  const onClick = () => {
    //Vid klick på knapp skicka eventet "file_download_research" till analytics
    //Ta gärna med analysens ID i eventet om det går?
    fireTrackEvent('Research', 'file_download_research', { researchId })
  }

  return (
    <SkeletonLoader loading={!item}>
      {() => (
        <GridContainer spacing={4} style={{ justifyContent: 'flex-start' }}>
          <GridItem xs={12} sm={8}>
            <FlexRow alignItems="center">
              {item?.marketIso && <CountryCodeFlag countryCode={item?.marketIso} size={16} />}
              <Text ml={4} variant="subtitle1">
                {item?.company?.name}
              </Text>
            </FlexRow>
            <Spacer height={12} />
            <FlexRow>
              <Heading5 css={{ lineHeight: 1.2 }}>{item?.headline}</Heading5>
            </FlexRow>
            <Spacer height={12} />
            <FlexRow gap={4} alignItems="center">
              {isCommissionedResearch && (
                <Box>
                  <CommissionedResearchIcon size={24} />
                </Box>
              )}
              <Text variant="overline">{`${t(isCommissionedResearch ? t('Uppdragsanalys') : t('Analys'))} ${
                item?.analysts ? ' - ' + item?.analysts : ''
              } - ${checkToday(new Date(item?.date), {
                short: true,
                showTodayAsTime: true,
              })}`}</Text>
            </FlexRow>
          </GridItem>
          <GridItem xs={12} md={8} lg={8}>
            {!item?.text && t('Ladda ner pdf:n för att få tillgång till hela analysen')}
            {item?.text && (
              <>
                <HtmlWithCustomComponents
                  replaceWithComponent={(node) => {
                    if (node.tagName.toLowerCase() === 'a') {
                      if (!Object.prototype.hasOwnProperty.call(node.attribs, 'href')) {
                        return <>{node.text}</>
                      }
                      if (node.attribs?.href?.endsWith('.pdf')) {
                        return (
                          <LinkExternal variant="body2" href={node.attribs.href}>
                            {node.text}
                          </LinkExternal>
                        )
                      }
                    }
                  }}
                  html={item?.text}
                />
                <Spacer height={32} />
                <DividerLine />
              </>
            )}
            <Spacer height={32} />
            {isMobile ? (
              <FooterContainer>
                <FlexRow flexGrow={1} mx={8} alignItems="center" justifyContent="center">
                  <DownloadButton researchId={researchId} onClick={onClick} width="full" />
                </FlexRow>
              </FooterContainer>
            ) : (
              <DownloadButton researchId={researchId} onClick={onClick} />
            )}
            {!isMobile && <Disclaimer isCommissionedResearch={isCommissionedResearch} />}
          </GridItem>
          <GridItem xs={12} md={8} lg={4}>
            {item?.sectorName && (
              <>
                <Segment title={t('Sektor')} variant="uncontained" headingVariant="overline">
                  <Tag>
                    <TagLabel>{item?.sectorName}</TagLabel>
                  </Tag>
                </Segment>
                <Spacer height={32} />
              </>
            )}
            <UpdateBasedOnResearch item={item} />
            <Spacer height={32} />
            <RelatedInstruments
              instruments={item?.relatedInstruments?.map((instrument) =>
                createInstrumentIdContainer(instrument.carnegieInstrumentId, instrument.infrontInstrument)
              )}
            />
            {isMobile && <Disclaimer isCommissionedResearch={isCommissionedResearch} />}
          </GridItem>
        </GridContainer>
      )}
    </SkeletonLoader>
  )
})

ResearchDrawer.displayName = 'ResearchDrawer'

type DownloadButtonProps = {
  researchId: string
  onClick: () => void
  width?: 'full'
}

const DownloadButton = ({ researchId, onClick, width }: DownloadButtonProps) => {
  const { t } = useTranslation()

  return (
    <ButtonLinkExternal
      startIcon={<Icon ml={4} color="current" icon={IconExternalLink} size={24} />}
      size="large"
      onClick={() => onClick}
      variant="primary"
      href={`/api/digital-channels/content/analysis/download/${researchId}`}
      rel="noreferrer"
      target="_blank"
      download={researchId}
      width={width}
    >
      {t('Läs hela analysen')}
    </ButtonLinkExternal>
  )
}
DownloadButton.displayName = 'DownloadButton'

const FooterContainer = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box
      position="fixed"
      left={0}
      right={0}
      bottom={0}
      backgroundColor="off-white"
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
      flexGrow={1}
      css={{ height: '72px', zIndex: 2 }}
    >
      {children}
    </Box>
  )
}
FooterContainer.displayName = 'FooterContainer'

export default ResearchDrawer
