import { BuySellMenuItem, PlaceFundOrderMenuItem, TransactionMenuItem } from '@common/components/ActionButton'
import { FormattedNumber, FormattedPercentage } from '@common/components/FormattedNumber'
import { MoreMenuWatchlist } from '@common/components/MoreMenu'

import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { FlexCol, MobileTableRow, Text } from '@carnegie/duplo'

import { observer } from 'mobx-react-lite'

import { useOpenInstrumentDrawer } from '../../../../drawers/instrumentDrawer/useOpenInstrumentDrawer'
import { InstrumentNameMobile } from '../InstrumentName'
import { ObservableTableInstrument } from '../useTableInstruments'

type Props = {
  instrument: ObservableTableInstrument
  accountCurrencyCode: string
  isFund: boolean
  showPercentage: boolean
  transactionOnClick?: () => void
}

/**
 * Mobile-optimized row component for displaying instrument details
 * Includes price changes, market value, and unrealized gains/losses
 */
export const TableOverviewMobileRow = observer(function TableOverviewMobileRow({
  instrument,
  accountCurrencyCode,
  showPercentage,
  isFund,
  transactionOnClick,
}: Props) {
  const { t } = useTranslation()
  const { openInstrumentDrawer } = useOpenInstrumentDrawer()

  const {
    carnegieInstrumentGroup,
    isoCountryCode,
    marketIso,
    marketValue,
    name,
    priceChangeToday,
    priceChangeTodayRatio,
    unrealized,
    unrealizedRatio,
    priceChangeOneDay,
    priceChangeOneDayRatio,
    reservedQuantities,
  } = instrument

  const showFlag = useMemo(() => carnegieInstrumentGroup !== 'MutualFunds', [carnegieInstrumentGroup])

  const handleInstrumentClick = useCallback(() => openInstrumentDrawer(instrument), [openInstrumentDrawer, instrument])

  // Memoize menu items to prevent unnecessary re-renders
  const menuItems = useMemo(
    () => (
      <MoreMenuWatchlist instrumentIdContainer={instrument} size="medium">
        {isFund ? (
          <>
            <PlaceFundOrderMenuItem instrumentIdContainer={instrument} fundOrderType={'buy'} />
            <PlaceFundOrderMenuItem instrumentIdContainer={instrument} fundOrderType={'sell'} />
          </>
        ) : (
          <BuySellMenuItem instrumentIdContainer={instrument} />
        )}
        <TransactionMenuItem onClick={transactionOnClick} />
      </MoreMenuWatchlist>
    ),
    [instrument, isFund, transactionOnClick]
  )

  // Memoize title component to prevent unnecessary re-renders
  const titleComponent = useMemo(
    () => (
      <InstrumentNameMobile
        isoCountryCode={isoCountryCode || marketIso}
        name={name}
        reservedQuantities={reservedQuantities}
        showFlag={showFlag}
      />
    ),
    [isoCountryCode, marketIso, name, reservedQuantities, showFlag]
  )

  // Memoize price change display value
  const priceChangeValue = useMemo(() => {
    if (showPercentage) {
      return (
        <FormattedPercentage
          colorized
          decimals={2}
          variant="subtitle2"
          value={isFund ? priceChangeOneDayRatio : priceChangeTodayRatio}
          ratio
        />
      )
    }
    return (
      <FormattedNumber
        variant="subtitle2"
        decimals={0}
        value={isFund ? priceChangeOneDay : priceChangeToday}
        unit={accountCurrencyCode}
        colorized
      />
    )
  }, [
    showPercentage,
    isFund,
    priceChangeOneDayRatio,
    priceChangeTodayRatio,
    priceChangeOneDay,
    priceChangeToday,
    accountCurrencyCode,
  ])

  // Memoize unrealized value display
  const unrealizedValue = useMemo(() => {
    if (showPercentage) {
      return <FormattedPercentage colorized decimals={2} value={unrealizedRatio} ratio variant="subtitle2" />
    }
    return <FormattedNumber variant="subtitle2" decimals={0} unit="SEK" value={unrealized} colorized />
  }, [showPercentage, unrealizedRatio, unrealized])

  return (
    <MobileTableRow divider action={menuItems} title={titleComponent} onClick={handleInstrumentClick}>
      <FlexCol alignItems="flex-start" flex={1}>
        <Text variant="label1" truncate>
          {isFund ? t('1 dag') : t('Idag')}
        </Text>
        {priceChangeValue}
      </FlexCol>
      <FlexCol alignItems="flex-start" flex={1}>
        <Text variant="label1" truncate>
          {t('Marknadsvärde')}
        </Text>
        <FormattedNumber variant="subtitle2" decimals={0} unit="SEK" value={marketValue} />
      </FlexCol>
      <FlexCol alignItems="flex-end" flex={1}>
        <Text variant="label1" truncate>
          {t('Orealiserat')}
        </Text>
        {unrealizedValue}
      </FlexCol>
    </MobileTableRow>
  )
})

TableOverviewMobileRow.displayName = 'TableOverviewMobileRow'
