/**
 * https://www.notion.so/Animations-and-Transitions-6fdf745a3a38404eb0758a86f8aa3d29#5bcea55eab23480e9503b3f0a0f80216
 */

export enum TransitionType {
  EXPANDING,
  COLLAPSING,
}

export enum TransitionSpeed {
  SLOW,
  MEDIUM,
  FAST,
}

enum TransitionEasing {
  STANDARD,
  IN,
  OUT,
}

enum TransitionDelay {
  SHORT = 'SHORT',
  MEDIUM = 'MEDIUM',
  LONG = 'LONG',
  EXTENDED = 'EXTENDED',
}

type Transition = {
  type: TransitionType
  speed?: TransitionSpeed
  easing?: TransitionEasing
  delay?: TransitionDelay
}

export const transition = ({
  type,
  speed = TransitionSpeed.MEDIUM,
  easing = TransitionEasing.STANDARD,
  delay,
}: Transition) => {
  const duration = getDuration(type, speed)
  const ease = getEasing(easing)
  const transitionConfig = { duration, ease }
  if (delay !== undefined) {
    return { ...transitionConfig, delay: getDelay(delay) }
  }
  return transitionConfig
}

function getDelay(delay: TransitionDelay | number) {
  if (typeof delay === 'number') return delay
  switch (delay) {
    case TransitionDelay.SHORT:
      return 0.1
    case TransitionDelay.MEDIUM:
      return 0.15
    case TransitionDelay.LONG:
      return 0.2
    case TransitionDelay.EXTENDED:
      return 0.25
  }
}

function getDuration(type: TransitionType, speed: TransitionSpeed) {
  switch (speed) {
    case TransitionSpeed.SLOW:
      return type === TransitionType.EXPANDING ? 0.3 : 0.25
    case TransitionSpeed.MEDIUM:
      return type === TransitionType.EXPANDING ? 0.25 : 0.2
    case TransitionSpeed.FAST:
      return 0.1
  }
}

function getEasing(easing: TransitionEasing = TransitionEasing.STANDARD) {
  switch (easing) {
    case TransitionEasing.STANDARD:
      return [0.4, 0.0, 0.2, 1]
    case TransitionEasing.IN:
      return [0.55, 0.05, 0.67, 0.19]
    case TransitionEasing.OUT:
      return [0.22, 0.61, 0.35, 1]
  }
}

export async function fadeOutIn(animation) {
  await animation.start({ opacity: 0.5, transition: { duration: 0.3 } })
  await animation.start({ opacity: 1, transition: { duration: 0.3 } })
}
