import { useSearchParams } from 'react-router-dom'

enum HoldingsGroup {
  InstrumentGroupName = 'InstrumentGroupName',
  Allocation = 'Allocation',
  PortfolioManagement = 'PortfolioManagement',
}

export const useHoldingsGroup = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const groupByParam = searchParams.get('groupBy') as HoldingsGroup
  const groupBy =
    groupByParam && Object.values(HoldingsGroup).includes(groupByParam)
      ? groupByParam
      : HoldingsGroup.InstrumentGroupName

  const setGroupBy = (newGroup: HoldingsGroup) => {
    searchParams.set('groupBy', newGroup)
    setSearchParams(searchParams)
  }

  return {
    holdingsGroups: HoldingsGroup,
    groupBy,
    setGroupBy,
  }
}
