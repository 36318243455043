import { useSyncPropsToStore } from '@common/hooks/useLocalStore'
import { SortOrder, useTable } from '@common/hooks/useTable'

import { CSSProperties, ReactNode, Ref, useImperativeHandle } from 'react'

import {
  Box,
  ButtonBox,
  FlexRow,
  Icon,
  IconChevronDown,
  IconChevronUp,
  Pagination,
  TBody,
  TFoot,
  THead,
  Table,
  Td,
  Th,
  Tr,
  useDuploTheme,
} from '@carnegie/duplo'

import { observer } from 'mobx-react-lite'

export type SmartTableRef<TColumnId extends string> = {
  sort: (columnId: TColumnId, sortOrder: SortOrder) => void
}

type SmartTableProps<TData, TColumnId extends string> = {
  className?: string
  columns: SmartTableColumnDefinition<TData, TColumnId>[]
  data: TData[]
  // Getting generic components working with mobx + forward ref is tricky so we make it simpler (this is a totally valid way)
  tableRef?: Ref<SmartTableRef<TColumnId>>
  /** Only used in cell mode */
  rowKey?: (row: TData, index: number) => string | number
  // For porting reasons, do not use if possible
  renderRow?: (props: RenderRowProps<TData, TColumnId>) => ReactNode
  renderFooterRow?: (props: RenderFooterRowProps<TData, TColumnId>) => ReactNode
  renderTableCellWrapper?: (props: RenderTableCellWrapperProps<TData>) => ReactNode
  sessionStorageKey?: string
  disableFooter?: boolean
  /** By setting page size you tell SmartTable to internally slice up the data in smaller parts forming a number of pages.
   * Do not use together with totalPages.
   */
  pageSize?: number
  /** When getting data from a pagination enabled API use this property to tell SmartTable how many pages exist.
   * Do not use this property together with page size, since you only pass in the items of the current page there is no need
   * for SmartTable to slice up the data for you.  */
  totalPages?: number
  /** By setting page yourself you take over control of the current page state. You must also use the onPageChange prop to update your own state when switching pages. */
  page?: number
  onPageChange?: (currentPage: number) => void
  defaultSortBy?: TColumnId
  /** Determines if we should render a `<table>`, `<thead>`, `<tbody>`, `<tr>`, `<td>` etc or let the user handle this themselves (for example when rendering a list in mobile etc) */
  renderMode?: 'table' | 'custom'
}

type SmartTableColumnDefinition<T, TColumnId extends string> = {
  visible?: boolean
  width: string | number
  align?: 'left' | 'right'
  id?: TColumnId
  renderHeader?: (props: RenderHeaderProps) => ReactNode
  renderCell?: (props: RenderCellProps<T>) => ReactNode
  renderFooter?: (props: RenderFooterProps) => ReactNode
  sortBy?: (props: SortByProps<T>) => string | number | boolean | Date
  defaultSortOrder?: SortOrder
}

type SortByProps<T> = { row: T }
type RenderTableCellWrapperProps<T> = { row: T; index: number; children: ReactNode }
type RenderCellProps<T> = { row: T; index: number }
type RenderRowProps<T, TColumnId extends string> = {
  row: T
  index: number
  columns: SmartTableColumnDefinition<T, TColumnId>[]
}
type RenderHeaderProps = { id: string }
type RenderFooterRowProps<T, TColumnId extends string> = {
  columns: SmartTableColumnDefinition<T, TColumnId>[]
}
type RenderFooterProps = {
  id: string
}

/**
 * SmartTable allows us to easily create tables with built in sorting, good performance etc.
 * For compatibility reasons the table can work in two "modes", "cell mode" and "row mode".
 * The preferred way is to use cell mode which makes it easier to rearrange cells, turn on off columns etc while keeping in sync with the position of the headers.
 * But since we have a lot of old tables that have separate components for rows and headers we need to support row mode also.
 * Use cell mode by specifying the renderCell prop on a column definition, and row mode by setting the renderRow prop of the table. Row mode takes precedence when set.
 */
export const SmartTable = observer(function SmartTable<TData, TColumnId extends string>({
  className,
  columns,
  data,
  rowKey,
  renderRow,
  renderFooterRow,
  sessionStorageKey,
  disableFooter,
  pageSize,
  renderMode = 'table',
  renderTableCellWrapper,
  defaultSortBy,
  page,
  totalPages,
  onPageChange,
  tableRef,
}: SmartTableProps<TData, TColumnId>) {
  const duploTheme = useDuploTheme()

  if (pageSize !== undefined && totalPages !== undefined) {
    throw new Error(
      'You should not use the pageSize and totalPages props together. Only use one or the other. If you use a pagination enabled API where you only get the items for the active page use totalPages, if you want SmartTable to partition the data for you (local pagination) use pageSize.'
    )
  }

  if (totalPages !== undefined && page === undefined) {
    throw new Error('When using the totalPages prop you must also set the page prop.')
  }

  // When setting the page and totalPages props we stop using the internal pagination logic from useTable
  // instead the user is must supply the page, totalPages and onPageChange logic themselves
  const manualPaginationHandling = !!page

  const showFooter =
    !disableFooter && (renderFooterRow !== undefined || columns.some((columnDef) => columnDef.renderFooter))

  const table = useTable(
    columns.map((columnDef) => {
      return {
        id: columnDef.id,
        sortBy: columnDef.sortBy,
        defaultSortOrder: columnDef.defaultSortOrder ? columnDef.defaultSortOrder : 'asc',
      }
    }),
    data,
    { sessionStorageKey: sessionStorageKey, pageSize: pageSize, defaultSortBy: defaultSortBy, defaultPage: page ?? 1 }
  )

  // Similar to use effect but runs during the same render
  useSyncPropsToStore(() => {
    table.setCurrentPage(page)
  }, [page])

  useImperativeHandle(
    tableRef,
    () => ({
      sort: (columnId, sortOrder) => {
        table.sort(columnId, sortOrder)
      },
    }),
    [table]
  )

  const alignCellStyles: Record<string, CSSProperties> = {}

  const visibleColumns = columns.filter((columnDef) => columnDef.visible !== false)

  visibleColumns.forEach((columnDef, index) => {
    alignCellStyles[`tr > *:nth-of-type(${index + 1})`] = {
      justifyContent: columnDef.align === 'right' ? 'flex-end' : undefined,
    }
  })

  const gridTemplateColumns = visibleColumns
    .map((columnDef) => (typeof columnDef.width === 'number' ? columnDef.width + 'px' : columnDef.width))
    .join(' ')

  const pagination = (
    <>
      {(manualPaginationHandling || table.numberOfPages > 1) && (
        <FlexRow alignItems="center" justifyContent="center" my={16} width="full">
          {manualPaginationHandling ? (
            <Pagination
              page={page}
              count={totalPages ? totalPages : table.numberOfPages}
              onChange={(_, newPage) => {
                onPageChange?.(newPage)
              }}
            />
          ) : (
            <Pagination
              page={table.currentPage}
              count={table.numberOfPages}
              onChange={(_, newPage) => {
                table.setCurrentPage(newPage)
              }}
            />
          )}
        </FlexRow>
      )}
    </>
  )

  // Override everything, for example when rendering a mobile table but you want to keep the sorting order/pagination etc
  if (renderMode === 'custom') {
    if (!renderRow) throw new Error('When doing a custom table render you must set the renderRow prop')
    return (
      <>
        {table.rows.map((row, index) => {
          return renderRow({ row: row, index: index, columns: visibleColumns })
        })}
        {renderFooterRow && showFooter && renderFooterRow({ columns: visibleColumns })}
        <Box>{pagination}</Box>
      </>
    )
  }

  return (
    <Box>
      <Table
        className={className}
        css={{
          width: '100%',
          display: 'grid',
          gridTemplateColumns: gridTemplateColumns,
          'thead, tbody, tfoot, tr': { display: 'contents' },
          'thead > tr > *': { borderBottom: duploTheme.borders['medium'] },
          'tfoot > tr > *': { borderTop: duploTheme.borders['medium'] },
          'tbody > tr:nth-of-type(odd) > *': { backgroundColor: duploTheme.colors['focus-background'] },
          // The effect when hovering a row, since a row is not displayed we must apply it to the child elements
          'tbody > tr:hover > *': { backgroundColor: duploTheme.colors['primary-hover'] },
          // Table cells + table cell headers themselves:
          'th, td': {
            display: 'flex',
            alignItems: 'center',
            height: 'auto',
            minHeight: 40,
            width: 'auto',
            // Without this headers will shrink and cut off stuff even when setting width auto
            overflow: 'visible',
          },
          ...alignCellStyles,
        }}
      >
        <THead>
          <Tr>
            {/* Render column headers */}
            {visibleColumns.map((columnDef, index) => {
              return (
                <Th
                  key={index}
                  onClick={columnDef.sortBy ? table.getHeaderById(columnDef.id)?.onClick : undefined}
                  // Needed to put the sort arrow on the correct side
                  textAlign={columnDef.align}
                  sortOrder={table.getHeaderById(columnDef.id)?.sortOrder}
                  sortable={!!columnDef.sortBy}
                >
                  {columnDef.renderHeader?.({ id: columnDef.id })}
                </Th>
              )
            })}
          </Tr>
        </THead>
        <TBody>
          {/* Render rows */}
          {table.rows?.map((row, index) => {
            // "Row mode"
            if (renderRow) {
              return renderRow({ row: row, index: index, columns: visibleColumns })
            } else {
              // "Cell mode"
              const cellKey = rowKey ? rowKey(row, index) : index
              return (
                <RowRenderer key={cellKey}>
                  {visibleColumns.map((columnDef, columnIndex) => {
                    const cellContent = <CellRenderer mobile={false} column={columnDef} row={row} index={index} />

                    // Use custom Td rendering?
                    if (renderTableCellWrapper) {
                      return renderTableCellWrapper({ index: columnIndex, row: row, children: cellContent })
                    } else {
                      return (
                        <Td textAlign={columnDef.align} pb={8} pt={8} key={columnIndex}>
                          {cellContent}
                        </Td>
                      )
                    }
                  })}
                </RowRenderer>
              )
            }
          })}
        </TBody>
        {renderFooterRow
          ? showFooter && renderFooterRow({ columns: visibleColumns })
          : showFooter && (
              <TFoot>
                <Tr>
                  {visibleColumns.map((columnDef, index) => (
                    <Td key={index}>
                      <FooterRenderer column={columnDef} />
                    </Td>
                  ))}
                </Tr>
              </TFoot>
            )}
      </Table>
      <Box>{pagination}</Box>
    </Box>
  )
})

SmartTable.displayName = 'SmartTable'

type FooterRendererProps<TData> = {
  column: SmartTableColumnDefinition<TData, string>
  className?: string
  children?: ReactNode
}

// The purpose of these components is to improve performance by adding a new mobx observer here.
// If a row changes values we can re-render a cell without re-rendering the entire table.
const FooterRenderer = observer(function FooterRenderer({ column }: FooterRendererProps<unknown>) {
  return <>{column.renderFooter?.({ id: column.id })}</>
})

FooterRenderer.displayName = 'FooterRenderer'

type RowRendererProps = { children?: ReactNode }

// The purpose of these components is to improve performance by adding a new mobx observer here.
// If a row changes values we can re-render a cell without re-rendering the entire table.
const RowRenderer = observer(function RowRenderer({ children }: RowRendererProps) {
  return <Tr>{children}</Tr>
})

RowRenderer.displayName = 'RowRenderer'

type CellRendererProps<TData> = {
  mobile: boolean
  row: TData
  column: SmartTableColumnDefinition<TData, string>
  className?: string
  children?: ReactNode
  index: number
}

// The purpose of these components is to improve performance by adding a new mobx observer here.
// If a cell changes values we can re-render a cell without re-rendering the entire table.
const CellRenderer = observer(function CellRenderer({ column, row, index }: CellRendererProps<unknown>) {
  return <>{column.renderCell?.({ row: row, index: index })}</>
})

CellRenderer.displayName = 'CellRenderer'

type ExpandableTrProps = {
  className?: string
  children?: ReactNode
  expanded?: boolean
  // A sub row will animate the entire row (values and all) + have different text color
  subRow?: boolean
  lastRow?: boolean
  bordered?: boolean
}

export const ExpandableTr = function ExpandableTr({
  children,
  expanded,
  subRow = false,
  lastRow,
  bordered,
}: ExpandableTrProps) {
  const theme = useDuploTheme()

  return (
    <Tr
      css={{
        'td:first-of-type': {
          boxShadow: expanded || subRow ? `inset 4px 0 ${theme.colors['status-positive']}` : undefined,
        },
        '&&': {
          td: {
            minHeight: subRow ? 32 : undefined,
          },
        },
        td: {
          borderTop: bordered ? theme.borders.medium : undefined,
          borderBottom: lastRow || bordered ? theme.borders.medium : undefined,
          color: subRow ? theme.colors['text-low-emphasis'] : undefined,
        },
      }}
    >
      {children}
    </Tr>
  )
}

ExpandableTr.displayName = 'ExpandableTr'

type ExpandTableButtonProps = {
  onClick?: () => void
  expanded: boolean
}

export const ExpandTableButton = function ExpandTableButton({ onClick, expanded }: ExpandTableButtonProps) {
  return (
    <ButtonBox onClick={onClick}>
      {!expanded && <Icon width={24} icon={IconChevronDown} color="icon-low-emphasis" />}
      {expanded && <Icon width={24} icon={IconChevronUp} color="status-positive" />}
    </ButtonBox>
  )
}

ExpandTableButton.displayName = 'ExpandTableButton'
